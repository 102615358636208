
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { LeaseAction } from '../../../../../states/lease/lease.actions';
import { PaymentAction } from '../../../../../states/payment/payment.actions';
import { Observable, combineLatest, filter, first } from 'rxjs';
import { CustomerPaymentStateModel } from '../../../../../states/payment/payment.model';
import { CommonService } from '../../../../../services/common.service';
import { LeaseStateModel } from '../../../../../states/lease/lease.model';
import { MaintenanceAction } from '../../../../../states/upload-documents/upload-documents.actions';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-lease',
  templateUrl: './lease.component.html',
  styleUrls: ['./lease.component.css']
})
export class LeaseComponent implements OnInit {

  leaseId: string
  payment$: Observable<CustomerPaymentStateModel>;
  lease$: Observable<LeaseStateModel>;
  nowdate: Date;

  constructor(private store: Store, private route: ActivatedRoute, private router: Router,
    private commonService: CommonService, private datePipe: DatePipe) {
    
  }

  ngOnInit() {

    this.nowdate = new Date();
    this.route.params.subscribe(params => {
      this.leaseId = params['leaseid'];
      this.commonService.startSpinner();      
      this.loadLeaseData();      
    });


  }

  isDataLoaded: boolean = false;
  hideLoaderIfAllDataIsLoaded() {
    
    combineLatest([this.lease$, this.maintenance$, this.payment$]).subscribe(
      ([lease, documents, payment]) => {
        if (lease && payment ) {
          this.isDataLoaded = true;
          this.commonService.stopSpinner();
        }
      },
      error => {
        console.error('Error loading data:', error);
        this.commonService.stopSpinner();
      }
    );
  }

  loadPaymentData() {

    this.store.dispatch(new PaymentAction.GetData(this.leaseId));
    this.payment$ = this.store.select(state => state.payment);
   
  }

  loadLeaseData() {
    
    this.store.dispatch(new LeaseAction.GetData(this.leaseId));
    this.lease$ = this.store.select(state => state.lease.lease);

    this.lease$.pipe(
      filter(lease => !!lease), // Ensure the lease data is not undefined or null
      first() 
    ).subscribe({
      next: leaseData => {

        this.loadPaymentData();
        this.loadMaintenanceData(leaseData?.baseUnitId);
        this.hideLoaderIfAllDataIsLoaded();

      },
      error: error => {
        console.error('Error fetching lease data:', error);
      }
    });
    
  }

  formatDate(_date: any): string | null {
    if (!_date)
      return "";
    // Parse the date string in UTC mode
    const date = new Date(_date.toString());
    // Format the date using Angular DatePipe, treating it as UTC
    return this.datePipe.transform(date, 'MM/dd/yyyy', 'UTC');
  }

  maintenance$: Observable<any>;
  loadMaintenanceData(baseUnitid: number) {    
    this.store.dispatch(new MaintenanceAction.GetMaintenance(baseUnitid));
    this.maintenance$ = this.store.select(state => state.documents.maintenance);   
  }

  isLeaseEndDateValid(leaseEndDate: string | Date): boolean {
    const leaseEndDateObj = new Date(leaseEndDate);
    return leaseEndDateObj >= this.nowdate;
  }

  routerLeaseLink() {
    this.router.navigate(['/payments'], {
      queryParams: { id: this.leaseId }
    });
    this.commonService.scrollRestoration();
  }
  routeUploadLink(leaseId: string) {
    this.router.navigate(['/maintenance/quarterly-maintenance-requirements'], {
      queryParams: { id: leaseId }
    });
    this.commonService.scrollRestoration();
  }

}
