import { Action, Select, State, StateContext, Store } from '@ngxs/store';
import { Observable, catchError, tap, throwError} from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpServiceService } from '../../services/http-service.service';
import { environment } from '../../../environments/environment';
import { CustomerState } from '../customer/customer.state';
import { AccountStateModel } from './account.model';
import { AccountAction } from './account.actions';
import { CommonService } from '../../services/common.service';

export interface IAccountStateModel {
  account: AccountStateModel | null;
  accountReponse: AccountStateModel | null;
}


@Injectable()
@State<IAccountStateModel>({
  name: 'account',
  defaults: {
    account: null,
    accountReponse: null
  }
})

export class AccountState {
  baseUrl: string;
  authorizedKey: string;

  @Select(CustomerState.GetCustomerId) customer$: Observable<string>
  constructor(private http: HttpServiceService,
    private store: Store, private commonService: CommonService) {
    this.baseUrl = environment.lease_path.base_uri;
    this.authorizedKey = environment.lease_path.key;
  }

  @Action(AccountAction.AddAccount)
  async addAccount(ctx: StateContext<IAccountStateModel>, action: AccountAction.AddAccount): Promise<any> {

    const id = await this.commonService.getLastValidValue(this.customer$);
    if (id == undefined)
      return

    action.payload.customerId = id;
    //let url = `${this.baseUrl}Customers/${id}/addContactInfo`;
    let url = `${this.baseUrl}Contact/addContactInfo`;
    return this.http.postDataWithToken(url, action.payload).pipe(
      tap((response: any) => {

        let res = response;
        ctx.patchState({ accountReponse: response });
      }),
      catchError((error) => {
        return throwError(error);
      })
    ).toPromise();

  }


  @Action(AccountAction.GetData)
  async onGetAccountData(ctx: StateContext<IAccountStateModel>) {

    const id = await this.commonService.getLastValidValue(this.customer$);
    if (id === undefined) {
      return;
    }

    const url = `${this.baseUrl}Contact/${id}`;
      this.http.getDataWithToken(url).pipe(
        tap((response: any) => {

          ctx.patchState({
            account: response
          });
        }),
        catchError((error: any) => {
          console.error('Error while fetching account api:', error);
          return throwError(error);
        })
      ).subscribe();

   
  }

}
