<h1 class="form-title">
  Accounts Information
</h1>

<div class="form-content account-information-content">

  <form class="example-form" [formGroup]="data" (ngSubmit)="onSubmit()">
    <div class="row justify-content-md-center">
      <div class="col-md-12">

        <div class="row mt-2">

          <div class="col-lg-6 col-md-6 col-sm-6 mb-3">
            <mat-label>First Name <span class="required-lable-txt">(Required)</span></mat-label>
            <mat-form-field class="example-full-width">
              <input matInput placeholder="" formControlName="firstName" [readonly]="isReadOnly">
              <mat-error>{{getErrorMessage("firstName")}}</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 mb-3">
            <mat-label>Last Name <span class="required-lable-txt">(Required)</span></mat-label>
            <mat-form-field class="example-full-width">
              <input matInput placeholder="" formControlName="lastName" [readonly]="isReadOnly">
              <mat-error>{{getErrorMessage("lastName")}}</mat-error>
            </mat-form-field>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <mat-label>Address 1 <span class="required-lable-txt">(Required)</span></mat-label>
            <mat-form-field class="example-full-width">
              <input matInput formControlName="address1"
                     #placesRef="ngx-places"
                     ngx-gp-autocomplete
                     [options]='options'
                     (onAddressChange)="handleAddressChange($event)" />
              <mat-error>{{getErrorMessage("address1")}}</mat-error>
            </mat-form-field>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6 mb-3">
            <mat-label>Address 2</mat-label>
            <mat-form-field class="example-full-width">
              <input matInput placeholder="" formControlName="address2" [readonly]="isReadOnly">
              <mat-error>{{getErrorMessage("address2")}}</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 mb-3">
            <mat-label>State <span class="required-lable-txt">(Required)</span></mat-label>
            <mat-form-field class="example-full-width">
              <mat-select formControlName="state">
                <mat-option *ngFor="let option of stateList" [value]="option.key">{{ option.value }}</mat-option>
              </mat-select>
              <mat-error>{{getErrorMessage("state")}}</mat-error>
            </mat-form-field>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6 mb-3">
            <mat-label>City <span class="required-lable-txt">(Required)</span></mat-label>
            <mat-form-field class="example-full-width">
              <input matInput placeholder="" formControlName="city" [readonly]="isReadOnly">
              <mat-error>{{getErrorMessage("city")}}</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 mb-3">
            <mat-label>Zip Code <span class="required-lable-txt">(Required)</span></mat-label>
            <mat-form-field class="example-full-width">
              <input matInput placeholder="" formControlName="zipcode" [readonly]="isReadOnly">
              <mat-error>{{getErrorMessage("zipcode")}}</mat-error>
            </mat-form-field>
          </div>

          <div class="col col-lg-6 col-md-6 col-sm-6">
            <mat-label>Phone <span class="required-lable-txt">(Required)</span></mat-label>
            <mat-form-field class="example-full-width">
              <input matInput placeholder="(___)___-____" formControlName="mobilePhone" [mask]="maskPhonePattern" required [readonly]="isReadOnly">
              <mat-error>{{getErrorMessage("mobilePhone")}}</mat-error>
            </mat-form-field>
          </div>
          <div class="col col-lg-6 col-md-6 col-sm-6">
            <mat-label>Email <span class="required-lable-txt">(Required)</span></mat-label>
            <mat-form-field class="example-full-width">
              <input matInput placeholder="pat@example.com" formControlName="email" required [readonly]="isReadOnly">
              <mat-error>{{getErrorMessage("email")}}</mat-error>
            </mat-form-field>
          </div>


        </div>



        <div class="row mt-3">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <button mat-stroked-button color="primary" type="button" class="blue-btn me-4" (click)="back()">Back</button>
            <button *ngIf="!isReadOnly" mat-stroked-button color="primary" type="submit" class="float-end ms-3">Request an update to Account Information</button>
            <button *ngIf="isReadOnly" mat-stroked-button color="primary" type="button" (click)="onEdit()" class="float-end">Edit</button>
          </div>
        </div>

      </div>
    </div>

  </form>

</div>
