import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {CustomMessageDisplayConfig, IMessageViewStateModel, MessageConfig} from './message-view.model';
import {MessageViewActions} from './message-view.actions';


@State<IMessageViewStateModel>({
  name: 'messageView',
  defaults: <IMessageViewStateModel>{
    icon: 'warning',
    message: ''
  }
})
@Injectable()
export class MessageViewState {

  @Selector()
  static getMessageConfig(state: IMessageViewStateModel): MessageConfig {
    return {
      icon: state.icon,
      message: state.message
    };
  }

  @Selector()
  static IsLoading(state: IMessageViewStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static IsWorking(state: IMessageViewStateModel): boolean {
    return state.busy;
  }

  @Action(MessageViewActions.Done)
  onDone(ctx: StateContext<IMessageViewStateModel>) {
    ctx.patchState({
      loading: false,
      busy: false
    });
  }

  @Action(MessageViewActions.Loading)
  onLoading(ctx: StateContext<IMessageViewStateModel>) {
    ctx.patchState({
      loading: true
    });
  }

  @Action(MessageViewActions.Working)
  onWorking(ctx: StateContext<IMessageViewStateModel>) {
    ctx.patchState({
      busy: true
    });
  }

  @Action(MessageViewActions.ApplyMessage)
  onApplyMessage(ctx: StateContext<IMessageViewStateModel>, action: MessageViewActions.ApplyMessage) {
    const key = action.key;
    const message = CustomMessageDisplayConfig[key].message;
    const icon = CustomMessageDisplayConfig[key].icon;
    ctx.patchState({
      message,
      icon: icon
    });
  }

}
