import { Component, ElementRef, Renderer2 } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription, catchError, tap, throwError } from 'rxjs';
import { LeaseStateModel } from '@states/lease/lease.model';
import { Select, Store } from '@ngxs/store';
import { CommonService } from '../../../../../services/common.service';
import { LeaseAction } from '@states/lease/lease.actions';
import { HttpServiceService } from '../../../../../services/http-service.service';
import { AddContact, ContactAction } from '@states/contact/contact.actions';
import { ContactStateModel } from '@states/contact/contact.model';
import { CustomerState } from '@states/customer/customer.state';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent {

  data: FormGroup;

  leases$: Observable<LeaseStateModel[]>;
  leaseSubscription: Subscription;

  contactIssueTypes$: Observable<any[]>;
  contactIssueTypesSubscription: Subscription;

  contactData: ContactStateModel = new ContactStateModel();
  @Select(CustomerState.GetCustomerId) customer$: Observable<string>

  LeaseList: { key: any, value: string }[] = [];
  IssueTypeList: { key: any, value: string }[] = [];

  toggle: boolean = true;
  chatTime: any;// = "Monday, 1:27 PM";
  input: string = "";
  loading = true;

  constructor(private store: Store, private http: HttpServiceService,
    private commonService: CommonService, private renderer: Renderer2,
    private elementRef: ElementRef, private datePipe: DatePipe) {
    const date = new Date();
    let currentDay = this.datePipe.transform(date, 'EEEE')?.toString();
    let currentTime = this.datePipe.transform(date, 'h:mm a');
    this.chatTime = currentDay + ", " + currentTime;

  }

  ngOnInit() {
    this.commonService.startSpinner();
    this.loadFormControls();
    this.loadContactData();
    this.loadLeaseData();
  }

  ngOnDestroy() {
    if (this.leaseSubscription) {
      this.leaseSubscription.unsubscribe();
    }
    if (this.contactIssueTypesSubscription) {
      this.contactIssueTypesSubscription.unsubscribe();
    }
  }

  loadFormControls() {
    this.data = new FormGroup({
      lease: new FormControl('', [Validators.required]),
      issueType: new FormControl('', [Validators.required]),
      message: new FormControl('', [Validators.required]),
    });
  }
  getErrorMessage(dataObject: any) {
    if (dataObject != null && this.isValidationEnable == true) {
      if (this.data.get(dataObject)?.hasError('required')) {
        return `You must enter a value`;
      } else if (this.data.get(dataObject)?.hasError('email')) {
        return `You must enter a valid value`;
      } else if (this.data.get(dataObject)?.hasError('numberField')) {
        return `You must enter a valid value`;
      } else if (this.data.get(dataObject)?.hasError('pattern')) {
        return `You must enter a valid value`;
      } else if (this.data.get(dataObject)?.hasError('maxlength')) {
        return `You must enter a max valid length`;
      } else if (this.data.get(dataObject)?.hasError('minlength')) {
        return `You must enter a min valid length`;
      } else if (this.data.get(dataObject)?.hasError('max')) {
        return `You must enter a max valid number`;
      }
    }
    return '';
  }

  loadLeaseData() {

    this.store.dispatch(new LeaseAction.GetLeasesData());
    this.leases$ = this.store.select(state => state.lease.leases);

    this.leaseSubscription = this.leases$?.subscribe(
      data => {
        let res = data?.length > 0 ? data : undefined;
        if (res) {
          this.addLeases(res);
        }
        this.commonService.stopSpinner();
        if (this.leaseSubscription) {
          this.leaseSubscription.unsubscribe();
        }
      },
      error => {
        console.error('Error:', error);
        this.commonService.stopSpinner();
      }
    )

  }
  loadContactData() {

    this.store.dispatch(new ContactAction.GetData);
    this.contactIssueTypes$ = this.store.select(state => state.contact.contact);

    this.contactIssueTypesSubscription = this.contactIssueTypes$?.subscribe(
      data => {
        var res = data;
        if (res) {
          this.addIssueTypes(res);
        }
        this.commonService.stopSpinner();
        if (this.contactIssueTypesSubscription) {
          this.contactIssueTypesSubscription.unsubscribe();
        }
      },
      error => {
        console.error('Error:', error);
        this.commonService.stopSpinner();
      }
    )

  }

  isValidationEnable: boolean = true;

  postContactData() {

    this.customer$.subscribe(id => {

      let leaseId = this.data.value?.lease || '';
      this.contactData = {
        leaseId: leaseId,
        issueTypeId: this.data.value?.issueType || 0,
        message: this.data.value?.message || '',
        createdBy: id,
      } as ContactStateModel;

    try {
      this.store.dispatch(new AddContact(this.contactData)).subscribe(
        (response: any) => {

          let res = response.contact.contact
          if (res) {

            this.resetForm();
            this.commonService.openSnackBar("The message has been sent successfully.", '', 'success');
            this.commonService.stopSpinner();
          }
          this.commonService.stopSpinner();
        },
        (error: any) => {
          this.commonService.openSnackBar('A technical error has occurred. Please reach out to the OTR Team for assistance.', '', 'error');
          this.commonService.stopSpinner();
        }
      );

    } catch (error: any) {
      this.commonService.openSnackBar(error.message, '', 'error');
      this.commonService.stopSpinner();
      }

    })
  }

  addLeases(res: Array<LeaseStateModel>): void {
    if (!this.LeaseList.length) {
      res.forEach(item => {
        this.LeaseList.push({
          key: item.id,
          value: item.leaseId
        })
      });

    }

  }

  addIssueTypes(res: any[]): void {
    res.forEach(item => {
      if (!this.keyExists(item.id, this.IssueTypeList)) {
        this.IssueTypeList.push({
          key: item.id,
          value: item.name
        });
      }
    });
  }

  keyExists(key: any, itemList: { key: string, value: string }[]): boolean {
    return itemList.some(item => item.key === key);
  }

  onSubmit() {
    this.isValidationEnable = true;
    if (this.data.invalid)
      return;

    this.commonService.startSpinner();
    this.postContactData();
  }

  resetForm() {
    this.isValidationEnable = false;
    this.data.reset({}, { emitEvent: false });
    //Object.keys(this.data.controls).forEach(key => {
    //  this.data.get(key)?.setErrors(null);
    //});
  }

  toggleChat() {
    this.toggle = !this.toggle;
  }

  PostComment() {
    if (this.input == "") {
      const input = document.getElementById('input');
      this.renderer.setStyle(input, 'border', 'red 1px solid');
    }
    else {
      const input = document.getElementById('input');
      this.renderer.setStyle(input, 'border', '');
      const container = this.elementRef.nativeElement.querySelector('#chatbody');
      // Create a new element
      const you = this.renderer.createElement('div');
      you.append(this.input);
      this.input = "";
      // Add a CSS class to the new element
      this.renderer.addClass(you, 'chat-bubble');
      this.renderer.addClass(you, 'you');
      this.loading = false;

      const yourtime = this.renderer.createElement('span');
      let yourT = this.datePipe.transform(new Date(), 'h:mm a');
      yourtime.append(yourT);
      this.renderer.addClass(yourtime, 'your-time');

      //set api here
      setTimeout(() => {
        const me = this.renderer.createElement('div');
        me.append("Hello I am a response");
        // Add a CSS class to the new element
        this.renderer.addClass(me, 'chat-bubble');
        this.renderer.addClass(me, 'me');
        this.loading = true;

        const metime = this.renderer.createElement('span');
        let meT = this.datePipe.transform(new Date(), 'h:mm a');
        metime.append(meT);
        this.renderer.addClass(metime, 'me-time');

        this.renderer.appendChild(container, me);
        this.renderer.appendChild(container, metime);
      }, 2000);
      //set api here

      // Append the new element to the container
      this.renderer.appendChild(container, you);
      this.renderer.appendChild(container, yourtime);
    }
  }
}
