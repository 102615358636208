import { Action, NgxsOnInit, Select, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { catchError, filter, first, map, mergeMap, Observable, of, switchMap, take, tap, throwError } from 'rxjs';
import { HttpServiceService } from '../../services/http-service.service';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { IActivateCustomer, RequestActivateCustomer, StatusType } from './customer.model';
import { CustomerActions } from './customer.actions';
import { HttpClient } from "@angular/common/http";

import { MessageViewActions } from "@states/message-view/message-view.actions";
import { CustomMessageKeys } from "@states/message-view/message-view.model";


@State<IActivateCustomer>({
  name: 'customer',
  defaults: <IActivateCustomer>{
    activationStatus: false,
  }
})

@Injectable()
export class CustomerState {

  baseUrl: string;
  authorizedKey: string;

  @Select(CustomerState.GetCustomerId) customer$: Observable<string>
  constructor(
    private readonly httpClient: HttpClient,
    private readonly http: HttpServiceService,
    private readonly store: Store,
    private readonly route: ActivatedRoute,
    private readonly router: Router) {
    this.baseUrl = environment.lease_path.base_uri;
  }
  get baseStateUrl() {
    return `${this.baseUrl}Customers`;
  }

  @Selector()
  static GetCustomerId(state: IActivateCustomer): any {
    return state.customerId;
  }
  @Selector()
  static IsInviteOfActiveStatus(state: IActivateCustomer): boolean {
    return state.activationStatus;
  }

  @Action(CustomerActions.SetInviteId)
  onSetInviteId(ctx: StateContext<IActivateCustomer>, { inviteId }: { inviteId: string }) {
    localStorage.setItem('inviteId', inviteId);
  }

  @Action(CustomerActions.GetActivationTokenStatus)
  onGetActivationTokenStatus(ctx: StateContext<IActivateCustomer>, { token }: { token: string }) {
    const url = `${this.baseStateUrl}/valid-activation?token=${token}`;
    return this.httpClient.get<StatusType>(url).pipe(
      mergeMap((response: StatusType) => {
        const { status: activationStatus } = response;
        ctx.patchState({
          activationStatus
        });
        console.log("here", response);
        return of(response)
      }),
    )
  }

  @Action(CustomerActions.GetActiveCustomer)
  onGetActiveCustomer(ctx: StateContext<IActivateCustomer>, { inviteId, userId }: { inviteId: string, userId: string }) {

    const url = `${this.baseUrl}Customers/activate-customer`;

    const activeCustomer = new RequestActivateCustomer(inviteId, userId);
    return this.http.postData(url, activeCustomer).pipe(
      map((response: any) => {

        let customerId: string | undefined;

        console.log("res", response)
        if (response) {
          customerId = response.customerId
        }
        else {
          this.store.dispatch(new MessageViewActions.ApplyMessage(CustomMessageKeys.ExpiredInvitation));
          this.router.navigate(['/error-message']).then(r => ({}));
        }

        ctx.patchState({
          customerId: customerId
        });
      }),
      catchError((error: any) => {
        console.error('Error while fetching active customer api:', error);
        return throwError(error);
      })
    )
  }

  @Action(CustomerActions.GetCustomerId)
  onGetCustomerIdByAuth(ctx: StateContext<IActivateCustomer>, { userId }: { userId: string }) {

    const url = `${this.baseUrl}Customers/by/${userId}`;
    return this.http.getData(url).pipe(
      map((response: any) => {

        let customerId: string | undefined;

        if (response)
          customerId = response.customerId

        ctx.patchState({
          customerId: customerId
        });

      }),
      catchError((error: any) => {
        console.error('Error while fetching active customer api:', error);
        return throwError(error);
      })
    )
  }
}
