<div class="material-dialog-content">

  <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="cancel"
            class="m-dialog-cancel-icon orange" (click)="onNoClick()"></mat-icon>

  <div class="row justify-content-md-center ms-3 mt-3 mb-3">
    <div class="col-md-12">
     
    </div>
  </div>

  <div mat-dialog-content>

    <div class="row">
      <div class="col-md-12 text-center">
        <mat-icon aria-hidden="false" aria-label="Example home icon" class="confirmation-icon" fontIcon="check_circle"></mat-icon>
        <h4>Your account information will be updated in 48 business hours</h4>
      </div>
    </div>

  </div>

</div>
