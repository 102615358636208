<footer class="footer">
  <div class="">
    <div class="row text-muted">
      <div class="col-12 text-center">
        <p class="mb-0">
          © OTR Leasing 2024
        </p>
      </div>

    </div>
  </div>
</footer>
