export namespace DashboardAction {
   export class getData {
     static readonly type = '[dashboard] Get Data';
  }

  export class OtrLeasingToken {
    static readonly type = '[Auth] OtrLeasingToken';
  }
}

