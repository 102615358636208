import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from '../../../../../services/common.service';
import { LimitsOfLiabilityDetailDialogComponent } from '../limits-of-liability-detail-dialog/limits-of-liability-detail-dialog.component';

@Component({
  selector: 'app-warranty-covered-parts',
  templateUrl: './warranty-covered-parts.component.html',
  styleUrls: ['./warranty-covered-parts.component.css']
})
export class WarrantyCoveredPartsComponent {

  constructor(
    private spinner: NgxUiLoaderService,
    public dialog: MatDialog,
    private commonService: CommonService
  ) {


  }


  openDialog(source: any): void {
    let _width = '700px';
    if (source == 'ENGINE ACCESSORY' || source == 'ENGINE' || source == 'AFTER TREATMENT')
      _width = '1000px';

    const dialogRef = this.dialog.open(LimitsOfLiabilityDetailDialogComponent, {     
    width: _width,
      autoFocus: false,
        enterAnimationDuration: '500ms',
          exitAnimationDuration: '1000ms',
            data: {
      name: source,
      },
  });

    dialogRef.afterClosed().subscribe((result) => {
  console.log('The dialog was closed');

});
  }


}
