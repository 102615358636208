<h1 class="form-title">
  DRS Information
</h1>

<div class="form-content">
  <div class="row justify-content-md-center">
    <div class="col-md-12" style="max-width: 800px;">

      <div class="row mb-5">
        <div class="col-md-12 text-center">
          <img src="assets/images/driver-road-service.png" class="drs-title-img">
        </div>
        <div class="col-md-12">
          <p class="drs-p">
            Control your maintenance expenses and secure peace of mind knowing that someone can help you stay on
            schedule and avoid costly delays. As our client, you get any time access to your <b>maintenance reserve fund</b>.
          </p>
        </div>

        <div class="col-md-12 features-benefits">
          <h2 class="f-b-heading text-center">Features & Benefits</h2>
          <div class="f-b-row">
            <div class="f-b-col">
              <mat-icon class="p-float-left-icons" fontIcon="schedule"></mat-icon> <span class="f-b-text">24/7/365 Access</span>
            </div>
            <div class="f-b-col">
              <mat-icon class="p-float-left-icons" fontIcon="groups"></mat-icon> <span class="f-b-text">20,000 Service Providers</span>
            </div>
            <div class="f-b-col">
              <mat-icon class="p-float-left-icons" fontIcon="sell"></mat-icon> <span class="f-b-text">Nationwide Discounts</span>
            </div>
          </div>
          <div class="f-b-row">
            <div class="f-b-col">
              <mat-icon class="p-float-left-icons" fontIcon="error"></mat-icon> <span class="f-b-text">Emergency Breakdown Services</span>
            </div>
            <div class="f-b-col">
              <mat-icon class="p-float-left-icons" fontIcon="paid"></mat-icon> <span class="f-b-text">Pay-Per-Use Service</span>
            </div>
          </div>
        </div>

        <div class="col-md-12 mt-4">
          <div>
            <mat-icon class="p-float-left-icons-2ndSec" fontIcon="check"></mat-icon>
            <p class="drs-p">
              <b>DRS</b> works directly with <b>OTR Leasing</b> to keep your maintenance reserve fund balance accurate and up-to-
              date for all repairs under this program.
            </p>
          </div>
        </div>
        <div class="col-md-12">
          <div>
            <mat-icon class="p-float-left-icons-2ndSec" fontIcon="check"></mat-icon>
            <p class="drs-p">
              <b>DRS</b> retains records of your repairs and can assist you in managing your expenses by providing copies of
              your transactions, including the service providers and <b>DRS'</b> invoices. We also offer the ability to download
              repair reports directly from our website.
            </p>
          </div>
        </div>
        <div class="col-md-12">
          <div>
            <mat-icon class="p-float-left-icons-2ndSec" fontIcon="check"></mat-icon>
            <p class="drs-p">
              Your maintenance reserve account is automatically established through <b>OTR Leasing</b>. Once your <b>DRS</b>
              account has funds deposited, you are ready to begin to use your account for repairs.
            </p>
          </div>
        </div>

        <div class="col-md-12 mt-2">
          <p class="text-center">
            The team at <b>Driver Road Services</b> understands that you are constantly on the go. As a result, they're committed to
            being available seven days a week. <b>Do you have an issue or question about your DRS account?</b>
          </p>
        </div>

        <div class="col-md-12 mt-2">
          <div class="text-center">
            <div class="float-start">
              <mat-icon class="left-icons-e-f" fontIcon="mail"></mat-icon>
              <span class="drs-email-phone-element"><a href="mailto:DRS@sirva.com">DRS@sirva.com</a></span>
            </div>
            <div class="float-end">
              <mat-icon class="left-icons-e-f" fontIcon="phone_in_talk"></mat-icon>
              <span class="drs-email-phone-element"><a href="tel:(800) 348-3732">(800) 348-3732</a></span>
            </div>
          </div>
          <div class="text-center mt-5">
            <img src="assets/images/drs_scanbar.png">
          </div>
        </div>

      </div>

    </div>
  </div>
</div>
