import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { HttpServiceService } from '../../services/http-service.service';
import { environment } from '../../../environments/environment';
import { catchError, map, throwError } from 'rxjs';
import { OtrTokenActions } from './otr-token.actions';
import { IOtrTokenModel } from './otr-token.model';


@State<IOtrTokenModel>({
  name: 'otrToken',
})

@Injectable()
export class OtrTokenState implements NgxsOnInit {

  baseUrl: string;
  authorizedKey: string;

  constructor(
    private http: HttpServiceService,
    private store: Store)
  {

    this.baseUrl = environment.lease_path.base_uri;
    this.authorizedKey = environment.lease_path.key;
  }
  ngxsOnInit(ctx: StateContext<any>): void {

    this.store.dispatch(new OtrTokenActions.OtrLeasingToken());

  }

  @Selector()
  static getOtrToken(state: IOtrTokenModel): any {
    return state.applicationToken;
  }

  @Action(OtrTokenActions.OtrLeasingToken)
  getOtrLeasingToken(ctx: StateContext<IOtrTokenModel>) {
    let token_url = `${this.baseUrl}Token`;

    return this.http.postData(token_url, { authorizedKey: this.authorizedKey }).pipe(
      map((response: IOtrTokenModel) => {

        ctx.patchState({
          applicationToken: response.applicationToken
        });

      }),
      catchError((error) => {
        console.error('Error while fetching token:', error);
        return throwError(error);
      })
    );
  }
}
