
<h1 class="form-title">
  <mat-icon class="orange" aria-hidden="false" aria-label="Example home icon" fontIcon="radio_button_checked"></mat-icon>
  One Time Payment
</h1>

<div class="form-content">

  <form class="example-form" [formGroup]="data" (ngSubmit)="onSubmit()">
    <div class="row justify-content-md-center">
      <div class="col-md-12">

        <div class="row">
          <div class="col col-lg-12">
            <p class="para">
              Simply enter the information below, press ‘Pay Now’, and then you’re all done! Any questions please contact Customer Service at (877) 440-6543.
            </p>
            <p class="three-percent-p">
              Payment will include a 3% processing fee.
            </p>
          </div>
        </div>

        <div class="row mt-2">
          <!--<div class="col-lg-6 col-md-6 col-sm-6 mb-3">
            <mat-label>Last 6 Of VIN Number <span class="required-lable-txt">(Required)</span></mat-label>
            <mat-form-field class="example-full-width">
              <input matInput placeholder="______" formControlName="lastVinNumber" [mask]="maskVinNumberPattern">
              <mat-error>{{getErrorMessage("lastVinNumber")}}</mat-error>
            </mat-form-field>
          </div>-->
          <div class="col-lg-6 col-md-6 col-sm-6 mb-3">
            <mat-label>Last 6 Of VIN Number <span class="required-lable-txt">(Required)</span></mat-label>

            <mat-form-field class="example-full-width">
              <input matInput
                     placeholder="______"
                     formControlName="lastVinNumber"
                     [matAutocomplete]="auto">

              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredVinNumberList | async" [value]="option.value">
                  {{ option.value }}
                </mat-option>
              </mat-autocomplete>

              <mat-error>{{ getErrorMessage('lastVinNumber') }}</mat-error>
            </mat-form-field>



            <!--<mat-form-field class="example-full-width">
    <mat-select formControlName="lastVinNumber">
      <mat-option *ngFor="let option of VinNumberList" [value]="option.key">{{ option.value }}</mat-option>
    </mat-select>
    <mat-error>{{getErrorMessage("lastVinNumber")}}</mat-error>
  </mat-form-field>-->
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 mb-3">
            <mat-label>Payment Amount <span class="required-lable-txt">(Required)</span></mat-label>
            <mat-form-field class="example-full-width"><!---->
              <input matInput placeholder="" formControlName="paymentAmount" min="0" (input)="onAmountChange($event)">
              <mat-error>{{getErrorMessage("paymentAmount")}}</mat-error>
            </mat-form-field>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <mat-label>Credit Card Number <span class="required-lable-txt">(Required)</span></mat-label>
            <mat-form-field class="example-full-width">
              <input matInput placeholder="________________" formControlName="creditCardNumber" [mask]="maskCCNumberPattern">
              <mat-error>{{getErrorMessage("creditCardNumber")}}</mat-error>
            </mat-form-field>
          </div>

          <div class="col-lg-4 col-md-6 col-sm-6 mb-3">
            <mat-label>Month Expiration <span class="required-lable-txt">(Required)</span></mat-label>
            <mat-form-field class="example-full-width">
              <mat-select formControlName="month">
                <mat-option *ngFor="let option of monthList" [value]="option.key">{{ option.value }}</mat-option>
              </mat-select>
              <mat-error>{{getErrorMessage("month")}}</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6 mb-3">
            <mat-label>Year Expiration <span class="required-lable-txt">(Required)</span></mat-label>
            <mat-form-field class="example-full-width">
              <mat-select formControlName="year">
                <mat-option *ngFor="let option of yearList" [value]="option.key">{{ option.value }}</mat-option>
              </mat-select>
              <mat-error>{{getErrorMessage("year")}}</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6 mb-3">
            <mat-label>CVC <span class="required-lable-txt">(Required)</span></mat-label>
            <mat-form-field class="example-full-width">
              <input matInput placeholder="___" formControlName="cvc" [mask]="maskCVCPattern">
              <mat-error>{{getErrorMessage("cvc")}}</mat-error>
            </mat-form-field>
          </div>

          <hr />

          <div class="col-lg-6 col-md-6 col-sm-6 mb-3">
            <mat-label>First Name <span class="required-lable-txt">(Required)</span></mat-label>
            <mat-form-field class="example-full-width">
              <input matInput placeholder="" formControlName="firstName" (keypress)="validateAlpha($event)">
              <mat-error>{{getErrorMessage("firstName")}}</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 mb-3">
            <mat-label>Last Name <span class="required-lable-txt">(Required)</span></mat-label>
            <mat-form-field class="example-full-width">
              <input matInput placeholder="" formControlName="lastName" (keypress)="validateAlpha($event)">
              <mat-error>{{getErrorMessage("lastName")}}</mat-error>
            </mat-form-field>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6 mb-3">
            <mat-label>Address <span class="required-lable-txt">(Required)</span></mat-label>
            <mat-form-field class="example-full-width">
              <input matInput formControlName="address"
                     #placesRef="ngx-places"
                     ngx-gp-autocomplete
                     [options]='options'
                     (onAddressChange)="handleAddressChange($event)" />
              <mat-error>{{getErrorMessage("address")}}</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 mb-3">
            <mat-label>City <span class="required-lable-txt">(Required)</span></mat-label>
            <mat-form-field class="example-full-width">
              <input matInput placeholder="" formControlName="city" (keypress)="validateAlpha($event)">
              <mat-error>{{getErrorMessage("city")}}</mat-error>
            </mat-form-field>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6 mb-3">
            <mat-label>State <span class="required-lable-txt">(Required)</span></mat-label>
            <mat-form-field class="example-full-width">
              <mat-select formControlName="state">
                <mat-option *ngFor="let option of stateList" [value]="option.key">{{ option.value }}</mat-option>
              </mat-select>
              <mat-error>{{getErrorMessage("state")}}</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 mb-3">
            <mat-label>Zip Code <span class="required-lable-txt">(Required)</span></mat-label>
            <mat-form-field class="example-full-width">
              <input matInput placeholder="" formControlName="zipcode" (keypress)="validateNumeric($event)">
              <mat-error>{{getErrorMessage("zipcode")}}</mat-error>
            </mat-form-field>
          </div>

          <hr />


        </div>

        <div class="row">
          <!--<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <p class="text-right">Fee: 3%*</p>
          </div>-->
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="position: relative; top: 15px;">
            <p style="position: absolute">Total</p>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <p class="text-right total-amt">${{ total | number:'1.2-2' }}</p>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <button mat-stroked-button color="primary" type="submit" class="float-end">Pay Now</button>
          </div>
        </div>

      </div>
    </div>

  </form>

</div>

<ng-template #successTemplate>
  <div class="container">
    <mat-icon aria-hidden="false" aria-label="Example home icon" class="confirmation-icon" fontIcon="check_circle"></mat-icon>
    <h1>Payment Confirmed</h1>
    <!--<p>Your payment has been successfully processed.</p>-->
    <p>{{responseModel.statusMessage}}</p>
    <div class="details">
      <div class="detail">
        <span class="label">Reference Number:</span>
        <span class="value">{{responseModel.transactionId}}</span>
      </div>
      <div class="detail">
        <span class="label">Total:</span>
        <span class="value">${{responseModel.total | number:'1.2-2'}}</span>
      </div>
      <div class="detail">
        <span class="label">Card Number:</span>
        <span class="value">{{responseModel.maskedCardNumber}}</span>
      </div>
      <div class="detail">
        <span class="label">Approval Message:</span>
        <span class="value">{{responseModel.approvalMessage}}</span>
      </div>
      <div class="detail">
        <span class="label">AVS Result:</span>
        <span class="value">{{responseModel.avsResponse}}</span>
      </div>
      <div class="detail">
        <span class="label">CSC Result:</span>
        <span class="value">{{responseModel.cscResponse}}</span>
      </div>
      <div class="detail">
        <span class="label">Customer Name:</span>
        <span class="value">{{responseModel.customerName}}</span>
      </div>
      <div class="detail">
        <span class="label">Invoice:</span>
        <span class="value">{{responseModel.invoice}}</span>
      </div>
    </div>
    <button mat-stroked-button color="primary" class="close-btn float-end" (click)="onCloseClick()">Close</button>
  </div>
</ng-template>
