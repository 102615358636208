
<h1 class="form-title">
  Lease Information
</h1>

<div class="form-content">

  <ng-container *ngIf="(lease$ | async) as lease">
    <form class="example-form">
      <div class="row mt-2">
        <div class="col-lg-12">
          <p class="sub-title-txt orange">
            Lease {{ leaseId }}            
          </p>
        </div>

        <ng-container *ngIf="(payment$ | async) as payment">
          <div class="col-lg-6 mb-4">
            <div>
              <mat-icon class="orange title-faq-icon" fontIcon="local_shipping"></mat-icon>
              <span class="faq-links-text">Truck : {{lease.assetModelYear}} {{lease.assetMake}} {{lease.assetModel}}</span>
            </div>
          </div>         
          <div class="col-lg-6 mb-4">
            <div>
              <mat-icon class="orange title-faq-icon" fontIcon="event"></mat-icon>
              <span *ngIf="(payment.leaseAutoDebitInd == 'true' ? true : false)" class="faq-links-text">Next Payment Scheduler for ACH : {{ formatDate(payment.nextDueDate) }}</span>
              <span *ngIf="!(payment.leaseAutoDebitInd == 'true' ? true : false)" class="faq-links-text">Payment Due Date : {{ formatDate(payment.nextDueDate) }}</span>
            </div>
          </div>
          <div class="col-lg-6 mb-4">
            <div>
              <mat-icon class="orange title-faq-icon" fontIcon="tag"></mat-icon>
              <span class="faq-links-text">Last 6 of VIN : {{lease.vin.slice(-6)}}</span>
            </div>
          </div>   
          <div class="col-lg-6 mb-4">
            <div>
              <mat-icon class="orange title-faq-icon" fontIcon="calendar_month"></mat-icon>
              <span class="faq-links-text" *ngIf="isLeaseEndDateValid(payment.nextDueDate); else noDueDate">
                Next PM Due Date : 
                <ng-container *ngIf="(maintenance$ | async) as maintenance">
                  {{ formatDate(maintenance.dueDate) }}
                </ng-container>
                <span class="d-l-link" (click)="routeUploadLink(lease.leaseId)"> Please Upload PM Documentation</span>
              </span>

              <ng-template #noDueDate>
                <span>You haven’t provided your PM information as required, <span class="d-l-link" (click)="routeUploadLink(lease.leaseId)">Please upload</span> or contact <a class="d-l-link">telematics@otrleasing.com</a></span>
              </ng-template>

            </div>
          </div>

          <div class="col-lg-6 mb-4">
            <div>
              <mat-icon class="orange title-faq-icon" fontIcon="payments"></mat-icon>
              <span class="faq-links-text d-l-link" (click)="routerLeaseLink()">Make a one time Payment</span>
            </div>
          </div>

        </ng-container>


      </div>
    </form>
  </ng-container>
</div>

