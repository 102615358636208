import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CommonService } from '../../services/common.service';
import { LeaseAction } from '../../states/lease/lease.actions';
import { LeaseStateModel } from '../../states/lease/lease.model';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  leases$: Observable<LeaseStateModel[]>;

  constructor(private router: Router,
    private commonService: CommonService,
    private store: Store) {
  }

  routerLink(path: string) {
    this.router.navigate([path], {
      //skipLocationChange: true,
    });
  }

  routerLeaseLink(leaseId: string, leaseVin: string) {
    this.router.navigate(['/accounts/lease/' + leaseId], {
    });
    this.store.dispatch(new LeaseAction.GetData(leaseId));
  }

  ngOnInit() {
    this.store.dispatch(new LeaseAction.GetLeasesData());
    this.leases$ = this.store.select(state => state.lease.leases);
  }

  isDropdownExpanded: { [key: string]: boolean } = {
    dropdown1: false,
    dropdown2: false,
    // Add more dropdown keys as needed
  };

  toggleDropdown(dropdownKey: string): void {
    this.isDropdownExpanded[dropdownKey] = !this.isDropdownExpanded[dropdownKey];
  }

}
