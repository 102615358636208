
<h1 class="form-title">
  Title FAQ
</h1>

<div class="row ms-4">
  <div class="col-lg-12">
    <p class="sub-title-txt">For All Registration And Title Questions:</p>
  </div>
  <div class="col-lg-12 mt-2">
    <div>
      <mat-icon class="orange title-faq-icon" fontIcon="mail"></mat-icon>
      <a href="mailto:titles@otrleasing.com" class="faq-links-text">titles@otrleasing.com</a>
    </div>
  </div>
  <div class="col-lg-12 mt-2">
    <mat-icon class="orange title-faq-icon" fontIcon="wifi_calling_3"></mat-icon>
    <span class="faq-links-text"><a href="tel:(877) 440-6543">(877) 440-6543</a></span>
  </div>
</div>

<div class="form-content">

  <form class="example-form">

    <div class="row mt-2">     
      <div class="col-lg-12 mt-3">
        <p class="paragraph-tag">
          Certain states/counties require different documentation for titling. Please refer to your individual DMV office to determine which documents are required to register the vehicle. We will provide as much documentation as requested to ensure proper titling.
        </p>
      </div>
    </div>


  </form>

</div>

