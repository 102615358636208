import { PaymentStateModel } from './payment.model';

export class AddPayment {
  static readonly type = '[Payment] Add Payment';
  constructor(public payload: PaymentStateModel) { }
}

export namespace PaymentAction {
  export class GetData {
    static readonly type = '[Payment] Get Data';
    constructor(public leaseId: string) { }
  }
}
