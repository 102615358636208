import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from '../../../../../services/common.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PaymentResponseModel, PaymentStateModel } from '../../../../../states/payment/payment.model';
import { Store } from '@ngxs/store';
import { AddPayment } from '../../../../../states/payment/payment.actions';
import { AuthActions } from '../../../../../states/auth/auth.actions';
import { NgxGpAutocompleteDirective } from '@angular-magic/ngx-gp-autocomplete';

import { AuthState } from '../../../../../states/auth/auth.state';
import { Observable, Subscription, map, startWith } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { LeaseAction } from '../../../../../states/lease/lease.actions';
import { LeaseStateModel } from '../../../../../states/lease/lease.model';
@Component({
  selector: 'app-onetime',
  templateUrl: './onetime.component.html',
  styleUrls: ['./onetime.component.css']
})
export class OnetimeComponent {

  data: FormGroup;
  monthList: { key: string; value: string }[] = [
    { key: '1', value: '1' },
    { key: '2', value: '2' },
    { key: '3', value: '3' },
    { key: '4', value: '4' },
    { key: '5', value: '5' },
    { key: '6', value: '6' },
    { key: '7', value: '7' },
    { key: '8', value: '8' },
    { key: '9', value: '9' },
    { key: '10', value: '10' },
    { key: '11', value: '11' },
    { key: '12', value: '12' }
  ]
  yearList: { key: string; value: string }[] = [
    { key: '2024', value: '2024' },
    { key: '2025', value: '2025' },
    { key: '2026', value: '2026' },
    { key: '2027', value: '2027' },
    { key: '2028', value: '2028' },
    { key: '2029', value: '2029' },
    { key: '2030', value: '2030' },
    { key: '2031', value: '2031' },
    { key: '2032', value: '2032' },
    { key: '2033', value: '2033' },
    { key: '2034', value: '2034' },

  ]
  stateList: { key: string, value: string }[] = [
    { key: 'AL', value: 'Alabama' },
    { key: 'AK', value: 'Alaska' },
    { key: 'AZ', value: 'Arizona' },
    { key: 'AR', value: 'Arkansas' },
    { key: 'CA', value: 'California' },
    { key: 'CO', value: 'Colorado' },
    { key: 'CT', value: 'Connecticut' },
    { key: 'DE', value: 'Delaware' },
    { key: 'FL', value: 'Florida' },
    { key: 'GA', value: 'Georgia' },
    { key: 'HI', value: 'Hawaii' },
    { key: 'ID', value: 'Idaho' },
    { key: 'IL', value: 'Illinois' },
    { key: 'IN', value: 'Indiana' },
    { key: 'IA', value: 'Iowa' },
    { key: 'KS', value: 'Kansas' },
    { key: 'KY', value: 'Kentucky' },
    { key: 'LA', value: 'Louisiana' },
    { key: 'ME', value: 'Maine' },
    { key: 'MD', value: 'Maryland' },
    { key: 'MA', value: 'Massachusetts' },
    { key: 'MI', value: 'Michigan' },
    { key: 'MN', value: 'Minnesota' },
    { key: 'MS', value: 'Mississippi' },
    { key: 'MO', value: 'Missouri' },
    { key: 'MT', value: 'Montana' },
    { key: 'NE', value: 'Nebraska' },
    { key: 'NV', value: 'Nevada' },
    { key: 'NH', value: 'New Hampshire' },
    { key: 'NJ', value: 'New Jersey' },
    { key: 'NM', value: 'New Mexico' },
    { key: 'NY', value: 'New York' },
    { key: 'NC', value: 'North Carolina' },
    { key: 'ND', value: 'North Dakota' },
    { key: 'OH', value: 'Ohio' },
    { key: 'OK', value: 'Oklahoma' },
    { key: 'OR', value: 'Oregon' },
    { key: 'PA', value: 'Pennsylvania' },
    { key: 'RI', value: 'Rhode Island' },
    { key: 'SC', value: 'South Carolina' },
    { key: 'SD', value: 'South Dakota' },
    { key: 'TN', value: 'Tennessee' },
    { key: 'TX', value: 'Texas' },
    { key: 'UT', value: 'Utah' },
    { key: 'VT', value: 'Vermont' },
    { key: 'VA', value: 'Virginia' },
    { key: 'WA', value: 'Washington' },
    { key: 'WV', value: 'West Virginia' },
    { key: 'WI', value: 'Wisconsin' },
    { key: 'WY', value: 'Wyoming' }
  ]
  paymentData: PaymentStateModel = new PaymentStateModel();
  total: number = 0;
  percentValue: number = 0.00;
  responseModel: PaymentResponseModel = new PaymentResponseModel();

  maskVinNumberPattern = 'AAAAAA';
  maskCCNumberPattern = '0000000000000000';
  maskCVCPattern = '000';
  @ViewChild('successTemplate') customTemplate: TemplateRef<any>;

  leaseId: string
  lease$: Observable<LeaseStateModel>;
  leaseSubscription: Subscription;

  constructor(private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private spinner: NgxUiLoaderService,
    private dialog: MatDialog) {


  }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      this.leaseId = params['id'];
      if (this.leaseId)
        this.loadLeaseData();
    });

    this.loadFormControls();   
  }

  ngOnDestroy() {
    if (this.leaseSubscription) {
      this.leaseSubscription.unsubscribe();
    }
  }

  leases$: Observable<LeaseStateModel[]>;
  VinNumberList: { key: any, value: string }[] = [];  
  filteredVinNumberList!: Observable<any[]>;

  loadLeaseData() {
    this.commonService.startSpinner();   

    this.store.dispatch(new LeaseAction.GetLeasesData());
    this.leases$ = this.store.select(state => state.lease.leases);

    this.leaseSubscription = this.leases$?.subscribe(
      data => {
        let res = data?.length > 0 ? data : undefined;
        if (res) {
          this.addVinNumber(res);
          var leaseData = res.find(x => x.leaseId === this.leaseId);
          if (leaseData) {           
            this.data?.patchValue({
              lastVinNumber: leaseData.vin.slice(-6),
            });
          }          

        }
        this.commonService.stopSpinner();        
      },
      error => {
        console.error('Error:', error);
        this.commonService.stopSpinner();
      }
    )


  }

  applyMask(value: string): string {
    const alphanumericRegex = /^[a-zA-Z0-9]*$/;
    let maskedValue = '';
    for (let i = 0; i < value.length && maskedValue.length < 6; i++) {
      const char = value[i];
      if (alphanumericRegex.test(char)) {
        maskedValue += char;
      }
    }
    return maskedValue;
  }
  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.VinNumberList.filter(option => option.value.toLowerCase().includes(filterValue));
  }

  addVinNumber(res: Array<LeaseStateModel>): void {
    if (!this.VinNumberList.length) {
      res.forEach(item => {
        let last6vin = item.vin.slice(-6);
        this.VinNumberList.push({
          key: last6vin,
          value: last6vin
        })
      });

    }

  }

  loadFormControls() {

    this.data = new FormGroup({
      lastVinNumber: new FormControl('', [Validators.required, Validators.maxLength(6), Validators.minLength(6)]),
      paymentAmount: new FormControl('', [Validators.required]),
      creditCardNumber: new FormControl('', [Validators.required, Validators.maxLength(16), Validators.minLength(16)]),
      month: new FormControl('', [Validators.required]),
      year: new FormControl('', [Validators.required]),
      cvc: new FormControl('', [Validators.required, Validators.maxLength(3), Validators.minLength(3)]),
      firstName: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z ]+$')]),
      lastName: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z ]+$')]),
      address: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      zipcode: new FormControl('', [Validators.required, Validators.pattern('^[0-9]+$')]),
    });

    this.filteredVinNumberList = this.data.controls['lastVinNumber'].valueChanges.pipe(
      startWith(''),
      map(value => this._filter(this.applyMask(value || '')))
    );

    this.data.controls['lastVinNumber'].valueChanges.subscribe(value => {      
      const maskedValue = this.applyMask(value || '');
      this.data.controls['lastVinNumber'].setValue(maskedValue, { emitEvent: false });
    });
  }

  getErrorMessage(dataObject: any) {
    if (dataObject != null) {
      if (this.data.get(dataObject)?.hasError('required')) {
        return `You must enter a value`;
      } else if (this.data.get(dataObject)?.hasError('email')) {
        return `You must enter a valid value`;
      } else if (this.data.get(dataObject)?.hasError('numberField')) {
        return `You must enter a valid value`;
      } else if (this.data.get(dataObject)?.hasError('pattern')) {
        return `You must enter a valid value`;
      } else if (this.data.get(dataObject)?.hasError('maxlength')) {
        return `You must enter a max valid length`;
      } else if (this.data.get(dataObject)?.hasError('minlength')) {
        return `You must enter a min valid length`;
      } else if (this.data.get(dataObject)?.hasError('max')) {
        return `You must enter a max valid number`;
      }
    }
    return '';
  }

  options = {
    // Define your autocomplete options here
    componentRestrictions: {
      country: ['US'],
    },
  };

  @ViewChild('ngxPlaces') placesRef: any = NgxGpAutocompleteDirective;

  public handleAddressChange(place: google.maps.places.PlaceResult) {
    console.log(place);
    // Do some stuff
    let addressfullvalue = place?.formatted_address;
    let addressComponents = place?.address_components as any[];
    if (!addressComponents) addressComponents = [];

    const { street, city, zip, state, fullAddress, shortAddress } =
      this.getAddressDetails(addressComponents);

    // Address fields
    this.data.patchValue({
      address: shortAddress,
      state: state,
      zipcode: zip,
      city: city,
    });
  }

  getAddressDetails(addressComponents: any[]) {
    let street = '';
    let city = '';
    let zip = '';
    let state = '';
    let fullAddress = '';
    let shortAddress = '';

    addressComponents.forEach((component) => {
      if (component.types.includes('street_number')) {
        street += component.long_name + ' ';
        shortAddress += component.short_name + ' ';
      } else if (component.types.includes('route')) {
        street += component.long_name;
        shortAddress += component.short_name;
      } else if (
        component.types.includes('locality') ||
        component.types.includes('sublocality') ||
        component.types.includes('sublocality_level_1') ||
        component.types.includes('postal_town')
      ) {
        city = component.long_name;
      } else if (component.types.includes('postal_code')) {
        zip = component.long_name;
      } else if (component.types.includes('administrative_area_level_1')) {
        state = component.short_name;
      }

      // Concatenate all components for full address
      fullAddress += component.long_name + ', ';
    });

    return { street, city, zip, state, fullAddress, shortAddress };
  }

  validateAlpha(event: KeyboardEvent) {
    const charCode = event.key.charCodeAt(0);
    if (!((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122) || charCode === 32)) {
      event.preventDefault();
    }
  }
  validateNumeric(event: KeyboardEvent) {
    const charCode = event.key.charCodeAt(0);
    if (!(charCode >= 48 && charCode <= 57)) { // 48-57 are char codes for 0-9
      event.preventDefault();
    }
  }

  onAmountChange(event: any) {
     let inputValue = event.target.value;
    // Remove any non-numeric characters except the decimal point
    inputValue = inputValue.replace(/[^0-9.]/g, '');
    // Ensure only one decimal point is present
    const parts = inputValue.split('.');
    if (parts.length > 2) {
      inputValue = parts[0] + '.' + parts.slice(1).join('');
    }
    // Convert to number and fix to two decimal places if needed
    const numericValue = parseFloat(inputValue);
    if (!isNaN(numericValue)) {
      // If there is a decimal point, limit to two decimal places
      if (inputValue.includes('.')) {
        const decimalIndex = inputValue.indexOf('.');
        const integerPart = inputValue.substring(0, decimalIndex);
        let decimalPart = inputValue.substring(decimalIndex + 1);
        // Limit decimal part to two digits
        if (decimalPart.length > 2) {
          decimalPart = decimalPart.substring(0, 2);
        }
        event.target.value = `${integerPart}.${decimalPart}`;
      } 
    } else {
      event.target.value = '';
    }


    let totalString = event.target.value == '' || event.target.value == null || event.target.value == undefined ? '0.00' : event.target.value;
    this.total = parseFloat(totalString);

    let totalAsNumber: number = this.total; // Example number
    let percentage: number = 3; // Percentage value (3%)
    this.percentValue = (percentage / 100) * totalAsNumber; // Calculate 3% of totalAsNumber
    let totalWithPercent: number = totalAsNumber + this.percentValue; // Add 3% value back to totalAsNumber

    this.total = totalWithPercent;
  }
 
  onSubmit() {    
    if (this.data.invalid)
      return;

    this.commonService.startSpinner();
    this.postPaymentData();
    
  }

  postPaymentData() {

    let fullName = (this.data.value?.firstName + " " + this.data.value?.lastName).trim();
    let paymentAmtString = this.data.value?.paymentAmount || '0';
    let paymentAmt = parseFloat(paymentAmtString);
    this.paymentData = {
      transactiontype: 'sale',
      amount: this.total || 0,
      creditcard: {
        number: this.data.value?.creditCardNumber || '',
        expirationmonth: this.data.value?.month || '',
        expirationyear: this.data.value?.year || ''
      },
      csc: this.data.value?.cvc || '',
      billingaddress: {
        name: fullName || '',
        streetaddress: this.data.value?.address || '',
        city: this.data.value?.city || '',
        state: this.data.value?.state || '',
        zip: this.data.value?.zipcode || '',
        country: 'US'
      },
      invoiceid: this.data.value?.lastVinNumber || '',
      discretionaryData: {
        additionalProp1: this.percentValue || '',
        additionalProp2: '',
        additionalProp3: ''
      },
      feeAmount: this.percentValue
    } as PaymentStateModel;
    
    try {
      this.store.dispatch(new AddPayment(this.paymentData)).subscribe(
        (response: any) => {
          
          //var payment = response.payment.payment
          this.responseModel = response.payment.payment
          //let isSuccess = payment.success;
          /*if (isSuccess) {*/
          if (this.responseModel.success) {
            this.responseModel.total = this.paymentData.amount;
            this.responseModel.invoice = this.paymentData.invoiceid;
            this.responseModel.customerName = fullName;
            this.data.reset();
            this.openDialog('700ms', '1000ms');
            //this.commonService.openSnackBar(payment.statusMessage, '', 'success');
            this.commonService.stopSpinner();
          }
          else {
            let msg = '';
            /*if (payment.errors != null && payment.errors.length > 0) {*/
            if (this.responseModel.errors != null && this.responseModel.errors.length > 0) {
              /*msg = payment.errors[0];*/
              msg = this.responseModel.errors[0];
            }
            else {
              /*msg = payment.statusMessage + ' - ' + payment.approvalMessage;*/
              msg = this.responseModel.statusMessage + ' - ' + this.responseModel.approvalMessage;
            }
            this.commonService.openSnackBar(msg, '', 'error');
            this.commonService.stopSpinner();
          }
          this.commonService.stopSpinner();
        },
        (error: any) => {
          this.commonService.openSnackBar('A technical error has occurred. Please reach out to the OTR Team for assistance.', '', 'error');
          this.commonService.stopSpinner();
        }
      );

    } catch (error: any) {
      this.commonService.openSnackBar(error.message, '', 'error');
      this.commonService.stopSpinner();
    }
  }

  routerComment() {
    this.router.navigate(['/payments/make-payment'], {
      skipLocationChange: true,
    });
  }

  openDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.dialog.open(this.customTemplate, {
      width: '500px',
      height: '550px',
      enterAnimationDuration,
      exitAnimationDuration,
    });
  }

  onCloseClick(): void {
    this.dialog.closeAll();
  }
}
