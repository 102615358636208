import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { OtrTokenState } from '../states/otr-token/otr-token.state';


@Injectable({
  providedIn: 'root',
})
export class HttpServiceService {

  @Select(OtrTokenState.getOtrToken) token$: Observable<string>

  constructor(private http: HttpClient) {
    
  } 

  
  postData(url: any, paylaod: any): Observable<any> {
    var response = this.http.post<any>(url, paylaod);
    return response;
  }

  postDataWithToken(url: any, payload: any): Observable<any> {
    return new Observable(observer => {
      this.token$.subscribe(token => {
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${token}`
        });
        const options = { headers: headers };
        this.http.post<any>(url, payload, options).subscribe(
          response => {
            observer.next(response);
            observer.complete();
          },
          error => {
            observer.error(error);
          }
        );
      });
    });
  }

  postDataWithOption(url: any, paylaod: any, options: any): Observable<any> {
    var response = this.http.post<any>(url, paylaod, options);
    return response;
  }

  uploadDocs(url: any, data: any) {
    return this.http.post(url, data);
  }

  getData(url: any): Observable<any> {
    var response = this.http.get<any>(url);
    return response;
  }

  getDataWithToken(url: any): Observable<any> {

    return new Observable(observer => {
      this.token$.subscribe(token => {
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${token}`
        });
        const options = { headers: headers };
        this.http.get<any>(url, options).subscribe(
          response => {
            observer.next(response);
            observer.complete();
          },
          error => {
            observer.error(error);
          }
        );
      });
    });
  }

  getDataWithOption(url: any, options: any): Observable<any> {
    var response = this.http.get<any>(url, options);
    return response;
  }

}
