import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {AuthService} from "@auth0/auth0-angular";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthActions } from '../../../states/auth/auth.actions';
import { Select, Store } from '@ngxs/store';
import { Observable, filter, mergeMap, of, map } from 'rxjs';
import { auth0User } from '../../../states/auth/auth.model';
import { AuthState } from '../../../states/auth/auth.state';
import { ActivatedRoute, CanActivate } from '@angular/router';


@Component({
  selector: 'login',
  templateUrl: 'login.component.html',
  standalone: true,
  imports: [

  ],
  styleUrls: [`login.component.scss`]
})

export class LoginComponent {

  @Select(AuthState.CurrentUser) user$: Observable<auth0User> | undefined;

  constructor(
    private readonly authService: AuthService,
    private readonly store: Store,
    ) {
  }

  login() {
    this.store.dispatch(new AuthActions.Login());
  }

  triggerLogin() {
    this.authService.loginWithRedirect(
      {
        authorizationParams: {
          redirect_uri: window.location.origin
        }
      }
    );
  }

}


