import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
//import { MainRoutingModule } from './main-routing.module';
//import { getComponents, getProviders } from './all';
//import { SharedModule } from '../../modules/shared/shared.module';
import {OnetimeComponent} from './pages/pay-bills/onetime/onetime.component';
import {SnackBarComponent} from './components/snack-bar/snack-bar.component';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';

import {NgxMaskDirective, NgxMaskPipe, provideNgxMask} from 'ngx-mask';
import {NgxCaptchaModule} from 'ngx-captcha';
import {NgxGpAutocompleteModule} from '@angular-magic/ngx-gp-autocomplete';
import {Loader} from '@googlemaps/js-api-loader';
import {MaterialModule} from '../../modules/material/material.module';
import {MakeAPaymentComponent} from './pages/pay-bills/make-a-payment/make-a-payment.component';
import {LoginComponent} from '../public/login/login.component';
import {LoginGuard} from '../../services/login.guard.service';
import {FaqComponent} from './pages/title-registration-process/faq/faq.component';
import {
  TruckRegistrationInfoComponent
} from './pages/title-registration-process/truck-registration-info/truck-registration-info.component';
import {DrsInformationComponent} from './pages/breakdown-resources/drs-information/drs-information.component';
import {
  WarrantyCoveredPartsComponent
} from './pages/breakdown-resources/warranty-covered-parts/warranty-covered-parts.component';
import {
  WarrantyClaimsProceduresComponent
} from './pages/breakdown-resources/warranty-claims-procedures/warranty-claims-procedures.component';
import {AuthConfigService, AuthService} from '@auth0/auth0-angular';
import {
  QuarterlyMaintenanceRequirementsComponent
} from './pages/preventative-maintenance/quarterly-maintenance-requirements/quarterly-maintenance-requirements.component';
import {
  InsuranceRequirementsComponent
} from './pages/insurance-information/insurance-requirements/insurance-requirements.component';
import {ContactComponent} from './pages/contact/contact/contact.component';
import {
  LimitsOfLiabilityDetailDialogComponent
} from './pages/breakdown-resources/limits-of-liability-detail-dialog/limits-of-liability-detail-dialog.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {ReportAnAccidentComponent} from './pages/insurance-information/report-an-accident/report-an-accident.component';
import {AccountsInformationComponent} from './pages/accounts/accounts-informaion/accounts-information.component';
import {LeaseComponent} from './pages/leases/lease/lease.component';
import {LoginSignUpGuard} from '../../services/signup.service';
import {
  AccountsInformationDialogComponent
} from './pages/accounts/accounts-information-dialog/accounts-information-dialog.component';
import { FleetPrideComponent } from './pages/breakdown-resources/fleet-pride/fleet-pride.component';

const routes: Routes = [
  { path: 'signup', component: LoginComponent, canActivate: [LoginSignUpGuard] },
  { path: 'login', component: LoginComponent, canActivate: [LoginGuard] },
  { path: 'payments', component: OnetimeComponent, canActivate: [LoginGuard] },
  { path: 'payments/make-payment', component: MakeAPaymentComponent, canActivate: [LoginGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [LoginGuard] },
  { path: 'accounts/lease/:leaseid', component: LeaseComponent, canActivate: [LoginGuard] },
  { path: 'resources/drs-information', component: DrsInformationComponent, canActivate: [LoginGuard] },
  { path: 'resources/warranty-covered-parts', component: WarrantyCoveredPartsComponent, canActivate: [LoginGuard] },
  { path: 'resources/warranty-claim-procedure', component: WarrantyClaimsProceduresComponent, canActivate: [LoginGuard] },
  { path: 'resources/fleet-pride', component: FleetPrideComponent, canActivate: [LoginGuard] },
  { path: 'maintenance/quarterly-maintenance-requirements', component: QuarterlyMaintenanceRequirementsComponent, canActivate: [LoginGuard] },
  { path: 'title/faq', component: FaqComponent, canActivate: [LoginGuard] },
  { path: 'title/truck-registration-info', component: TruckRegistrationInfoComponent, canActivate: [LoginGuard] },
  { path: 'insurance/insurance-requirements', component: InsuranceRequirementsComponent, canActivate: [LoginGuard] },
  { path: 'insurance/report-an-accident', component: ReportAnAccidentComponent, canActivate: [LoginGuard] },
  { path: 'accounts/information', component: AccountsInformationComponent, canActivate: [LoginGuard] },
  { path: 'contact', component: ContactComponent, canActivate: [LoginGuard] },
]

@NgModule({
  declarations: [
    //...getComponents(),
    /*LoginComponent,*/
    OnetimeComponent,
    SnackBarComponent,
    MakeAPaymentComponent,
    LeaseComponent,
    FaqComponent,
    TruckRegistrationInfoComponent,
    DashboardComponent,
    DrsInformationComponent,
    WarrantyCoveredPartsComponent,
    WarrantyClaimsProceduresComponent,
    FleetPrideComponent,
    QuarterlyMaintenanceRequirementsComponent,
    InsuranceRequirementsComponent,
    ContactComponent,
    LimitsOfLiabilityDetailDialogComponent,
    ReportAnAccidentComponent,
    AccountsInformationComponent,
    AccountsInformationDialogComponent
  ],
  imports: [

    CommonModule,
    //MainRoutingModule,
    //SharedModule,
    NgOptimizedImage,
    HttpClientModule,
    FormsModule, ReactiveFormsModule,
    RouterModule.forChild(routes),
    MaterialModule,
    NgxCaptchaModule,
    NgxGpAutocompleteModule,
    NgxMaskDirective, NgxMaskPipe
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [

    provideNgxMask(),
            {
              provide: Loader ,
              useValue: new Loader({
                apiKey: 'AIzaSyDEq-9vJqYuh2Z-lOj4SICDHjcD0y09LdI',
                libraries: ['places']
              })
    },
    {
      provide: AuthService,
      useValue: {}
    },
    {
      provide: AuthConfigService,
      useValue: {}
    },
  ]

})
export class MainModule { }
