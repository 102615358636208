import {CustomMessageKey} from "./message-view.model";

export namespace MessageViewActions{
  export class Loading {
    static readonly type = '[Message View] Set As Loading';
  }

  export class Working {
    static readonly type = '[Message View] Set As Working';
  }

  export class Done {
    static readonly type = '[Message View] Set As Done';
  }
  export class ApplyMessage{
    static readonly type = '[Message View] Apply Message';
    constructor(public readonly key: CustomMessageKey){}
  }

}


