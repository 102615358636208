import { AccountStateModel } from "./account.model";

export namespace AccountAction {

  export class AddAccount {
    static readonly type = '[Account] Add Account';
    constructor(public payload: AccountStateModel) { }
  }

  export class GetData {
    static readonly type = '[Account] Get Data';
    //constructor(public leaseId: string) { }
  }

}
