import { Component } from '@angular/core';

@Component({
  selector: 'app-report-an-accident',
  templateUrl: './report-an-accident.component.html',
  styleUrls: ['./report-an-accident.component.css']
})
export class ReportAnAccidentComponent {

}
