import { Component } from '@angular/core';

@Component({
  selector: 'app-drs-information',
  templateUrl: './drs-information.component.html',
  styleUrls: ['./drs-information.component.css']
})
export class DrsInformationComponent {

}
