import { Action, NgxsOnInit, Selector, State, StateContext, Store, Select } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ActivateCustomer, IAuthStateModel } from './auth.model';
import { AuthActions } from './auth.actions';
import { catchError, filter, first, firstValueFrom, map, mergeMap, Observable, switchMap, take, tap, throwError } from 'rxjs';
import { AuthService } from "@auth0/auth0-angular";
import { HttpServiceService } from '../../services/http-service.service';
import { environment } from '../../../environments/environment';
import { DashboardAction } from '../dashboard/dashboard.actions';
import { ActivatedRoute } from '@angular/router';
import { CustomerActions } from '../customer/customer.actions';
import { select } from 'd3';
import { CustomerState } from '../customer/customer.state';
import { CommonService } from '../../services/common.service';


@State<IAuthStateModel>({
  name: 'auth',
  defaults: <IAuthStateModel>{
    loading: true,
    busy: false,
    isAuthenticated: false,
    isActive: true
  }
})

@Injectable()
export class AuthState implements NgxsOnInit {

  baseUrl: string;
  authorizedKey: string;

  @Select(CustomerState.GetCustomerId) customer$: Observable<string>

  constructor(
    private readonly authService: AuthService,
    private http: HttpServiceService,
    private store: Store,
    private route: ActivatedRoute) {

    this.baseUrl = environment.lease_path.base_uri;
    this.authorizedKey = environment.lease_path.key;
  }
  ngxsOnInit(ctx: StateContext<any>): void {
  }

  @Selector()
  static IsLoading(state: IAuthStateModel): boolean {
    return state.loading;
  }
  @Selector()
  static IsDone(state: IAuthStateModel): boolean {
    return !state.loading;
  }

  @Selector()
  static IsUserInactive(state: IAuthStateModel): boolean {
    return !state.isActive;
  }

  @Selector()
  static IsWorking(state: IAuthStateModel): boolean {
    return state.busy;
  }
  @Selector()
  static CurrentUser(state: IAuthStateModel): any {
    return state.user;
  }

  @Selector()
  static IsAuthenticated(state: IAuthStateModel): any {
    return state.isAuthenticated;
  }

  @Action(AuthActions.Done)
  onDone(ctx: StateContext<IAuthStateModel>) {
    ctx.patchState({
      loading: false,
      busy: false
    });
  }

  @Action(AuthActions.Loading)
  onLoading(ctx: StateContext<IAuthStateModel>) {
    ctx.patchState({
      loading: true
    });
  }

  @Action(AuthActions.Working)
  onWorking(ctx: StateContext<IAuthStateModel>) {
    ctx.patchState({
      busy: true
    });
  }

  @Action(AuthActions.ApplyUserSession)
  onApplyUserSession(ctx: StateContext<IAuthStateModel>) {

    const inviteId = localStorage.getItem('inviteId') ?? '';
    localStorage.removeItem('inviteId');

    return this.authService.user$.pipe(
      tap(user => {

        ctx.patchState({
          user: user,
          isAuthenticated: true
        });

        const userId = user?.sub ?? '';
        
        if (inviteId) {
          this.store.dispatch(new CustomerActions.GetActiveCustomer(userId, inviteId)).pipe(
            tap(m => {
              //console.log("m.customer", m.customer);
              const customerId = m.customer.customerId;
              ctx.patchState({
                customerId: customerId ?? '',
                isActive: !!customerId
              });
             
            })
          ).subscribe();
        }
        else {
          this.store.dispatch(new CustomerActions.GetCustomerId(userId)).pipe(
            tap(m => {
              const customerId = m.customer.customerId;
              ctx.patchState({
                customerId: customerId ?? '',
                isActive: !!customerId
              });              
            })
          ).subscribe();
        }
      })
  )}


  @Action(AuthActions.Login)
  onLogin(ctx: StateContext<IAuthStateModel>) {
    return this.authService.logout().pipe(

      tap(user => {
        ctx.patchState({
          user: null,
          isAuthenticated: false
        });

        this.authService.loginWithRedirect({
          authorizationParams: {
            redirect_uri: window.location.origin
          }
        });

      })
    );
  }

  @Action(AuthActions.LogOut)
  onLogOut(ctx: StateContext<IAuthStateModel>) {
    return this.authService.logout().pipe(

      tap(user => {
        ctx.patchState({
          user: null,
          isAuthenticated: false
        });

        this.authService.loginWithRedirect({
          authorizationParams: {
            redirect_uri: window.location.origin
          }
        });

      })
    );
  }

}
