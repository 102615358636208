import { Component } from '@angular/core';

@Component({
  selector: 'app-fleet-pride',
  templateUrl: './fleet-pride.component.html',
  styleUrls: ['./fleet-pride.component.css']
})
export class FleetPrideComponent {

}
