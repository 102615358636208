import { Component } from '@angular/core';

@Component({
  selector: 'app-warranty-claims-procedures',
  templateUrl: './warranty-claims-procedures.component.html',
  styleUrls: ['./warranty-claims-procedures.component.css']
})
export class WarrantyClaimsProceduresComponent {

}
