
<h1 class="form-title">
  Truck Registration Info
</h1>

<div class="row ms-4">
  <div class="col-lg-12">
    <p class="sub-title-txt">Truck Registration Information</p>
  </div>
</div>

<div class="form-content">

  <form class="example-form">

    <div class="row">
      <div class="col-lg-12 mt-3">
        <p class="paragraph-tag">
          The lessee is responsible for registration; not OTR Leasing. The lessee is also responsible for all fees, taxes, and lessee-specific documentation (such as Form 2290 or DOT registration). OTR Leasing does not know what documents the lessee needs until they are requested.
        </p>
      </div>
      <div class="col-lg-12 mt-2">
        <p class="paragraph-tag">
          A copy of the title and the lease agreement are usually enough to register the truck. If any other documents are needed, such as a power of attorney, please call (888)-338-9948 to speak with someone in the Titling/Registration Department.
        </p>
      </div>
      <div class="col-lg-12 mt-2">
        <p class="paragraph-tag">
          If your state requires the truck to be titled in that state as part of registration, OTR Leasing can help with that.
        </p>
      </div>
      <div class="col-lg-12 mt-2">
        <ul class="t-registration-ul">
          <li>
            OTR Leasing will only send titles to titling offices, titling agents, Department of Motor Vehicles, or similar agencies. The title will not be released to the lessee until the lease is complete.
          </li>
          <li>
            All titles are initially held electronically. Some state DMVs may require 2-3 weeks to process titles. Once we are in possession of the title, it typically takes 3 days for OTR Leasing to provide the title externally.
          </li>
          <li>
            Once OTR Leasing has the title, we send it out via USPS Express at no cost to the lessee. If the title needs to be overnighted, there is a $45.00 fee that the lessee will need to pay upfront
          </li>
          <li>
            OTR Leasing will be listed as the owner on the new title. If necessary, the lessee's name will appear on the title as lessee. OTR Leasing may have another lienholder listed on the title as well.
          </li>
        </ul>
      </div>
      <div class="col-lg-12">
        <p class="paragraph-tag">
          This is a lease, not a purchase. Be clear when registering, as it will change what documents you will need.
        </p>
      </div>
    </div>

  </form>

</div>

