<div class="material-dialog-content">

  <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="cancel"
            class="m-dialog-cancel-icon orange" (click)="onNoClick()"></mat-icon>

  <div class="row justify-content-md-center ms-3 mt-3 mb-3">
    <div class="col-md-12">
      <h2>{{data.name}}</h2>
    </div>
  </div>

  <div mat-dialog-content>

    <div *ngIf="data.name == 'ENGINE'">
      <div class="row">
        <div class="col-lg-6">
          <p class="d-subtitle">Internally Lubricated Parts Including:</p>
          <ul class="w-d-ul">
            <li>Balance & Timing Belt Tensioners</li>
            <li>Connecting Rods          </li>
            <li>Connecting Rod & Main Bearings          </li>
            <li>Constant Velocity Valve          </li>
            <li>Crankshaft           </li>
            <li>Cylinder Liners*             </li>
            <li>Engine Block*         </li>
            <li>Main Thrust Washers          </li>
            <li>Pistons       </li>
            <li>Piston Rings          </li>
            <li>Timing Chain & Gears          </li>
            <li>Timing Chain Tensioners       </li>
            <li>Timing Gear Cover     </li>
            <li>Wrist Pins    </li>
          </ul>

          <br />
          <p class="d-subtitle">LUBRICATING SYSTEM</p>
          <ul class="w-d-ul">
            <li>Oil Pan             </li>
            <li>Oil Cooler & Housing          </li>
            <li>Oil Pump Housing & Gears          </li>
            <li>Oil Pump Pick Up Screen & Tube          </li>
            <li>Oil Pump Pressure Relief Springs & Valves</li>
            <li> Piston Oil Cooling Jets             </li>
          </ul>
          <br />
          <p class="d-subtitle">FAN/FAN DRIVE ASSEMBLES</p>
          <ul class="w-d-ul">
            <li>Belt Tighteners             </li>
            <li>Fan Blades Shroud          </li>
            <li>Fan Clutch          </li>
            <li>Fan Group          </li>
            <li>Harmonic Balancer           </li>
          </ul>
        </div>

        <div class="col-lg-6">
          <p class="d-subtitle">OVERHEAD COMPONENETS</p>
          <ul class="w-d-ul">
            <li>Cam Followers             </li>
            <li>Camshaft          </li>
            <li>Camshaft Bearings          </li>
            <li>Cylinder Head*          </li>
            <li>Hydraulic Lifters           </li>
            <li>Intake/Exhaust Valves             </li>
            <li>Intake/Exhaust Valves Guides         </li>
            <li>Pushrods          </li>
            <li>Rocker Arms       </li>
            <li>Rocker Arm Shafts          </li>
            <li>Valve Keepers (locks)          </li>
            <li>Valve Retainers       </li>
            <li>Valve Springs     </li>
            <li>Valve Cover    </li>
            <li>Variable Valve Camshaft Adjuster</li>
          </ul>

          <br />
          <p class="d-subtitle">RELATED ITEMS</p>
          <ul class="w-d-ul">
            <li>Exhaust Manifold             </li>
            <li>Flywheel Housing          </li>
            <li>Intake Manifold          </li>
            <li>Vibration Dampener          </li>
          </ul>

          <br />
          <p class="d-p-text mt-3">
            *THE ENGINE BLOCK, CYLINDER LINERS, & CYLINDER HEADS ARE COVERED ONLY IF DAMAGED BY A COVERED COMPONENTS.
          </p>

        </div>

      </div>
    </div>

    <div *ngIf="data.name == 'TRANSMISSION'" class="row">
      <div class="col-lg-12">
        <p class="d-subtitle">Internally Lubricated Parts Including:</p>
        <ul class="w-d-ul">
          <li>Bands             </li>
          <li>Bearings          </li>
          <li>Blockers          </li>
          <li>Bushings          </li>
          <li>Clutch*           </li>
          <li>Drums             </li>
          <li>Gear Sets         </li>
          <li>Governor          </li>
          <li>Input Shaft       </li>
          <li>Oil Pump          </li>
          <li>Shaft(s)          </li>
          <li>Shift Forks       </li>
          <li>Synchronizers     </li>
          <li>Thrust Washers    </li>
          <li>Torque Converter  </li>
          <li>Transmission Case*</li>
          <li>Valve Body</li>
          <li>XY Shifter</li>
        </ul>
        <p class="d-p-text mt-3">
          *THE TRANSMISSION CASE IS COVERED ONLY IF DAMAGED BY A <br />
          COVERED COMPONENT. CLUTCH COVERED IF FAILED; PARTS ONLY AND <br />
          NO PROGRESSIVE DAMAGE.
        </p>
      </div>

    </div>

    <div *ngIf="data.name == 'DIFFERENTIAL(s)'" class="row">
      <div class="col-lg-12">
        <p class="d-subtitle">Internally Lubricated Parts Including:</p>
        <ul class="w-d-ul">
          <li>Axle Shafts             </li>
          <li>Bearings                </li>
          <li>Bushings                </li>
          <li>Carrier Case            </li>
          <li>Differential Housing*   </li>
          <li>Gear Sets               </li>
          <li>Limited Slip Clutch Pack</li>
          <li>Power Divider Bearings</li>
          <li>Gears</li>
          <li>Shafts</li>
          <li>Shift Fork</li>
          <li>Thrust Washer(s)</li>
        </ul>
        <p class="d-p-text mt-3">
          *THE DIFFERENTIAL HOUSING IS COVERED ONLY IF DAMAGED BY A <br />
          COVERED COMPONENT.
        </p>
      </div>

    </div>


    <div *ngIf="data.name == 'AFTER TREATMENT'">
      <div class="row">
        <div class="col-lg-6">
          <p class="d-subtitle">DIESEL EXHAUST FLUID SYSTEM</p>
          <ul class="w-d-ul">
            <li>(DEF) Heated Lines</li>
            <li>(DEF) Heater Control Relay          </li>
            <li>(DEF) Heater Coolant Control          </li>
            <li>Valve          </li>
            <li>(DEF) Heating Elements           </li>
            <li>(DEF) Heating Fittings             </li>
            <li>(DEF) Injection Nozzle         </li>
            <li>(DEF) Module          </li>
            <li>(DEF) Pump       </li>
            <li>(DEF) Tank          </li>
          </ul>

          <br />
          <p class="d-subtitle">DIESEL PARTICULATE</p>
          <ul class="w-d-ul">
            <li>Diesel Particulate Filter (DPF)             </li>
            <li>DPF Air/Fuel/Coolant Lines          </li>
            <li>DPF Air/Fuel/ Manifold Assembly          </li>
            <li>DPF Dosing Module          </li>
          </ul>
          <br />
          <p class="d-subtitle">HYDROCARBON DOSER SYSTEM</p>
          <ul class="w-d-ul">
            <li>Diesel Oxidation Catalyst (DOC)             </li>
            <li>Hydrocarbon Doser Injector (HC doser, 7th Injector,ARD, AHI Module)</li>
            <li>Hydrocarbon Doser Valve - Air/Fuel/Coolant Lines & Inline Air Filter</li>
            <li>Hydrocarbon Doser Valve (Voss Valve)          </li>
          </ul>
        </div>

        <div class="col-lg-6">
          <p class="d-subtitle">EXHAUST GAS RECIRCULATION (EGR)</p>
          <ul class="w-d-ul">
            <li>BPV Actuator**             </li>
            <li>BPV (Back Pressure Control Valve)**          </li>
            <li>EGR Actuator**          </li>
            <li>EGR Connecting Bellows & Clamps**          </li>
            <li>EGR Cooler(s)**           </li>
            <li>EGR Cooler Lines**             </li>
            <li>Exhaust Piping/Clamps/ Gaskets**         </li>
            <li>Exhaust Throttle Valve**          </li>
            <li>EGR Valves**       </li>
            <li class="mt-4">**NO PROGRESSIVE DAMAGE          </li>
          </ul>

          <br />
          <p class="d-subtitle">SELECTIVE CATALYTIC REDUCTION</p>
          <ul class="w-d-ul">
            <li>Selective Catalytic Reduction (SCR) Catalyst             </li>
            <li>Selective Catalytic Reduction (SCR) Decomp Pipe/Elbows          </li>
          </ul>

        </div>

      </div>
    </div>

    <div *ngIf="data.name == 'ELECTRICAL'" class="row">
      <div class="col-lg-12">

        <ul class="w-d-ul">
          <li>After Treatment Control Module (ACM)             </li>
          <li>Electronic Control Module (ECM) - Includes the ECM Unit                </li>
        </ul>

        <p class="d-subtitle mt-3">SENSORS-AFTERTREATMENT</p>
        <ul class="w-d-ul">
          <li>After Treatment Regeneration Flame Detection Temperature Sensor             </li>
          <li>ASM Sensor                </li>
          <li>Back Pressure Valve Temperature Sensor                </li>
          <li>Back Pressure Valve Pressure Sensor            </li>
          <li>Burner Flame Temperature Sensor   </li>
          <li>Catalytic Converter (downstream) NOX Sensor               </li>
          <li>Catalytic Converter (upstream) NOX Sensor</li>
          <li>Catalytic Converter Temperature Sensor</li>
          <li>Catalytic Reduction Actuator (downstream) Temperature Sensor</li>
          <li>Catalytic Reduction Actuator (upstream) Temperature Sensor</li>
          <li>Catalytic Reduction Actuator Temperature/Fill Level Sensor</li>
          <li>Catalytic Reduction Actuator Temperature/Moisture Sensor</li>
          <li>Clean Gas Induction (CGI) Cooler Combustion Air Delta Sensor</li>
          <li>Clean Gas Induction (CGI) Cooler Pressure Sensor</li>
          <li>Clean Gas Induction (CGI) Cooler Temperature Sensor</li>
          <li>Compensation Sensor</li>
          <li>Delta P Sensor</li>
          <li>Diesel Exhaust Fluid (DEF) Tank Fill Level Sensor</li>
          <li>Diesel Exhaust Fluid (DEF) Temperature Sensor</li>
          <li>Diesel Exhaust Fluid (DEF) Quality Sensor</li>
          <li>Diesel Exhaust System Nitrogen Oxide (NOx) Sensor</li>
          <li>Diesel Oxidation Catalyst (DOC) Gas Temperature Sensor</li>
          <li>Diesel Oxidation Catalyst (DOC) Pressure Sensor</li>
          <li>Diesel Particulate Filter Ammonia Sensor</li>
          <li>Doser Valve Pressure Sensor</li>
          <li>Exhaust Aftertreatment Fuel Control Module Sensor</li>
          <li>Exhaust Aftertreatment Particulate Trap Differential Pressure Sensor</li>
          <li>Exhaust Aftertreatment Pressure Sensor</li>
          <li>Exhaust Back Pressure Sensor</li>
          <li>Exhaust Gas Recirculation (EGR) Monitor Sensor</li>
          <li>Exhaust Gas Recirculation (EGR) Position Sensor</li>
          <li>Exhaust Gas Recirculation (EGR) Pressure Sensor</li>
          <li>Exhaust Gas Temperature Sensor</li>
          <li>Exhaust Oxygen (02/Lamda) Sensor</li>
          <li>Exhaust Temperature Sensor</li>
          <li>Exhaust Soot Sensor</li>
          <li>Input Exhaust Gas Sensor</li>
          <li>Metering Device Compressed Air Pressure Sensor</li>
          <li>Metering Device Pressure Sensor</li>
          <li>Metering Sevice Temperature Sensor</li>
          <li>Nitrogen Oxide (NOx) Sensor(s)</li>
          <li>Output Exhaust Gas Pressure Sensor</li>
          <li>Particulate Soot/Matter Sensor (PM Sensor)</li>
          <li>Selective Catalytic Reduction Air Intake Humidity Sensor</li>
          <li>Selective Catalytic Reduction System Inlet/Outlet</li>
          <li>Selective Catalytic Reduction Temperature Sensor</li>
          <li>Upstream DPF Temperature Sensor</li>
        </ul>
      </div>

    </div>

  


    <div *ngIf="data.name == 'ENGINE ACCESSORY'">
      <div class="row">
        <div class="col-lg-4">
          <p class="d-subtitle">COOLING SYSTEM</p>
          <ul class="w-d-ul">
            <li>Alternator</li>
            <li>Charge Air Cooler</li>
            <li>Engine Thermostat</li>
            <li>Engine Thermostat Housing</li>
          </ul>

          <br />
          <p class="d-subtitle">FUEL PUMPS</p>
          <ul class="w-d-ul">
            <li>Fuel Injection Pump</li>
            <li>Fuel Lift Pump</li>
            <li>Fuel Prime Pump</li>
            <li>Fuel Transfer Pump</li>
            <li>High/Low-Pressure Fuel Pump</li>
          </ul>

          <br />
          <p class="d-subtitle">FUEL INJECTORS</p>
          <ul class="w-d-ul">
            <li>Failed Injectors</li>
            <li>Failed Injector Cups</li>
            <li>Fuel injector Hard Lines</li>
            <li>Fuel Intake Manifold</li>
            <li>*Fuel Injector Tubes & Seals</li>
          </ul>
        </div>

        <div class="col-lg-4">
          <p class="d-subtitle">RADIATOR</p>
          <ul class="w-d-ul">
            <li>Radiator</li>
          </ul>

          <br />
          <p class="d-subtitle">STARTER</p>
          <ul class="w-d-ul">
            <li>Armature</li>
            <li>Bearings</li>
            <li>Boot</li>
            <li>Brushes</li>
            <li>Brush Holder</li>
            <li>Bushings</li>
            <li>Coil</li>
            <li>Drive Assembly Insulator</li>
            <li>Jumper</li>
            <li>Magnet</li>
            <li>Motor Shaft Lever & Spring</li>
            <li>Plunger</li>
            <li>Solenoid</li>
            <li>Starter Drive Gear</li>
          </ul>

        </div>

        <div class="col-lg-4">
          <p class="d-subtitle">TURBOCHARGER(S)</p>
          <ul class="w-d-ul">
            <li>Actuator</li>
            <li>Bearings</li>
            <li>Internal Variable Vane Assembly</li>
            <li>Turbines</li>
            <li>Turbine Shaft</li>
            <li>Turbocharger Housing</li>
            <li>VPOD</li>
            <li>Wastegate</li>
          </ul>

          <br />
          <p class="d-subtitle">WATER PUMP</p>
          <ul class="w-d-ul">
            <li>Bearings</li>
            <li>Housing</li>
            <li>Impeller</li>
            <li>Seals</li>
            <li>Water Manifold</li>
          </ul>

          <br />
          <p class="d-p-text mt-3">
            **NO PROGRESSIVE DAMAGE
          </p>

        </div>

      </div>
    </div>



  </div>

</div>


