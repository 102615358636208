import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from '../../../../../services/common.service';
import { Router } from '@angular/router';
import { PaymentStateModel } from '../../../../../states/payment/payment.model';
import { Store } from '@ngxs/store';
import { AddPayment } from '../../../../../states/payment/payment.actions';

@Component({
  selector: 'app-make-a-payment',
  templateUrl: './make-a-payment.component.html',
  styleUrls: ['./make-a-payment.component.css']
})
export class MakeAPaymentComponent {

  data: FormGroup;
  monthList: { key: string; value: string }[] = [
    { key: 'Month', value: 'Month' },
    { key: '1', value: '1' },
    { key: '2', value: '2' },
    { key: '3', value: '3' },
    { key: '4', value: '4' },
    { key: '5', value: '5' },
    { key: '6', value: '6' },
    { key: '7', value: '7' },
    { key: '8', value: '8' },
    { key: '9', value: '9' },
    { key: '10', value: '10' },
    { key: '11', value: '11' },
    { key: '12', value: '12' }
  ]
  yearList: { key: string; value: string }[] = [
    { key: 'Year', value: 'Year' },
    { key: '2024', value: '2024' },
    { key: '2025', value: '2025' },
    { key: '2026', value: '2026' },
    { key: '2027', value: '2027' },
    { key: '2028', value: '2028' },
    { key: '2029', value: '2029' },
    { key: '2030', value: '2030' },
    { key: '2031', value: '2031' },
    { key: '2032', value: '2032' },
    { key: '2033', value: '2033' },
    { key: '2034', value: '2034' },
    
  ]
  stateList: { key: string, value: string }[] = [
    { key: 'AL', value: 'Alabama' },
    { key: 'AK', value: 'Alaska' },
    { key: 'AZ', value: 'Arizona' },
    { key: 'AR', value: 'Arkansas' },
    { key: 'CA', value: 'California' },
    { key: 'CO', value: 'Colorado' },
    { key: 'CT', value: 'Connecticut' },
    { key: 'DE', value: 'Delaware' },
    { key: 'FL', value: 'Florida' },
    { key: 'GA', value: 'Georgia' },
    { key: 'HI', value: 'Hawaii' },
    { key: 'ID', value: 'Idaho' },
    { key: 'IL', value: 'Illinois' },
    { key: 'IN', value: 'Indiana' },
    { key: 'IA', value: 'Iowa' },
    { key: 'KS', value: 'Kansas' },
    { key: 'KY', value: 'Kentucky' },
    { key: 'LA', value: 'Louisiana' },
    { key: 'ME', value: 'Maine' },
    { key: 'MD', value: 'Maryland' },
    { key: 'MA', value: 'Massachusetts' },
    { key: 'MI', value: 'Michigan' },
    { key: 'MN', value: 'Minnesota' },
    { key: 'MS', value: 'Mississippi' },
    { key: 'MO', value: 'Missouri' },
    { key: 'MT', value: 'Montana' },
    { key: 'NE', value: 'Nebraska' },
    { key: 'NV', value: 'Nevada' },
    { key: 'NH', value: 'New Hampshire' },
    { key: 'NJ', value: 'New Jersey' },
    { key: 'NM', value: 'New Mexico' },
    { key: 'NY', value: 'New York' },
    { key: 'NC', value: 'North Carolina' },
    { key: 'ND', value: 'North Dakota' },
    { key: 'OH', value: 'Ohio' },
    { key: 'OK', value: 'Oklahoma' },
    { key: 'OR', value: 'Oregon' },
    { key: 'PA', value: 'Pennsylvania' },
    { key: 'RI', value: 'Rhode Island' },
    { key: 'SC', value: 'South Carolina' },
    { key: 'SD', value: 'South Dakota' },
    { key: 'TN', value: 'Tennessee' },
    { key: 'TX', value: 'Texas' },
    { key: 'UT', value: 'Utah' },
    { key: 'VT', value: 'Vermont' },
    { key: 'VA', value: 'Virginia' },
    { key: 'WA', value: 'Washington' },
    { key: 'WV', value: 'West Virginia' },
    { key: 'WI', value: 'Wisconsin' },
    { key: 'WY', value: 'Wyoming' }
  ]
  paymentData: PaymentStateModel = new PaymentStateModel();
  total: number = 0.00;

  constructor(private store: Store, private router: Router, private ngxService: NgxUiLoaderService, private commonService: CommonService) {

  }

  ngOnInit(): void {

    this.loadFormControls();

  }

  loadFormControls() {

    this.data = new FormGroup({
      lastVinNumber: new FormControl('', [Validators.required]),
      paymentAmount: new FormControl('', [Validators.required]),
      creditCardNumber: new FormControl('', [Validators.required]),
      month: new FormControl('', [Validators.required]),
      year: new FormControl('', [Validators.required]),
      cvc: new FormControl('', [Validators.required]),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      zipcode: new FormControl('', [Validators.required]),
    });

    this.data.patchValue({
      paymentAmount: this.commonService.amount
    })
    this.total = this.commonService.amount;

  }


  getErrorMessage(dataObject: any) {
    if (dataObject != null) {
      if (this.data.get(dataObject)?.hasError('required')) {
        return `You must enter a value`;
      } else if (this.data.get(dataObject)?.hasError('email')) {
        return `You must enter a valid value`;
      } else if (this.data.get(dataObject)?.hasError('numberField')) {
        return `You must enter a valid value`;
      } else if (this.data.get(dataObject)?.hasError('pattern')) {
        return `You must enter a valid value`;
      } else if (this.data.get(dataObject)?.hasError('maxlength')) {
        return `You must enter a max valid length`;
      } else if (this.data.get(dataObject)?.hasError('minlength')) {
        return `You must enter a min valid length`;
      } else if (this.data.get(dataObject)?.hasError('max')) {
        return `You must enter a max valid number`;
      }
    }
    return '';
  }

  onSubmit() {
    //this.spinner.start();
    if (this.data.invalid) {
      return;
    }
    // this.spinner.stop();
  }

  postDataOnRewiew() {
    
   

  }


  backToPayment() {
    this.router.navigate(['/payments'], {
      skipLocationChange: true,
    });
  }

}
