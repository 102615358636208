import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { CommonService } from '../../../../../services/common.service';
import { MaintenanceAction } from '../../../../../states/upload-documents/upload-documents.actions';
import { FileDTO } from '../../../../../states/upload-documents/upload-documents.model';
import { CustomerPaymentStateModel } from '../../../../../states/payment/payment.model';
import { Observable, Subscription } from 'rxjs';
import { LeaseStateModel } from '../../../../../states/lease/lease.model';
import { LeaseAction } from '../../../../../states/lease/lease.actions';
import { FormControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-quarterly-maintenance-requirements',
  templateUrl: './quarterly-maintenance-requirements.component.html',
  styleUrls: ['./quarterly-maintenance-requirements.component.css']
})
export class QuarterlyMaintenanceRequirementsComponent {

  documents: FileDTO[] = [];
  @ViewChild('fileInput') fileInput: ElementRef;
  selectedFiles: File[] = [];

  payment$: Observable<CustomerPaymentStateModel>;
  maintenance$: Observable<any>;

  leases$: Observable<LeaseStateModel[]>;
  leaseSubscription: Subscription;

  LeaseList: { key: any, value: string }[] = [];
  leases: LeaseStateModel[] = [];
  leaseId: string = '';

  constructor(private commonService: CommonService, private store: Store, private route: ActivatedRoute, private datePipe: DatePipe) {
  }

  ngOnInit() {

    this.commonService.startSpinner();
    this.loadLeaseData();

  }

  onFileSelected(event: any) {
    this.selectedFiles = event.target.files;
    const maxSize = 20 * 1024 * 1024; // 20MB in bytes
    for (let i = 0; i < this.selectedFiles.length; i++) {
      const file = this.selectedFiles[i];
      if (file.size > maxSize) {
        console.log('File size should be less than 20MB:', file.name);
        this.commonService.openSnackBar('File size should be less than 20MB', '', 'error');
        event.target.value = null;
        return;
      }
      console.log('Valid file:', file.name);
    }

    if (this.selectedFiles[0]) {
      this.documents = [{ file: this.selectedFiles[0] }];
    }
    //for (const file of this.selectedFiles) {
    //  this.documents.push({ file: file });
    //}
  }

  triggerFileInput(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.fileInput.nativeElement.click();
  }

  removeUploadedFile(event: any, fileName: any, type: string = '') {
    this.documents = this.documents.filter(file => file.file?.name !== fileName);
    const target = event.target as HTMLElement;
    const parent = target.closest('.file-link');
    if (parent) parent.remove();
  }


  loadMaintenanceData(baseUnitid: number) {

    this.store.dispatch(new MaintenanceAction.GetMaintenance(baseUnitid));
    this.maintenance$ = this.store.select(state => state.documents.maintenance);

    this.commonService.stopSpinner();
  }

  leaseControl = new FormControl('');
  selectLease(leaseId: string) {
    this.leaseControl.setValue(leaseId);
    this.onLeaseChange(leaseId);
  }

  loadLeaseData() {

    this.store.dispatch(new LeaseAction.GetLeasesData());
    this.leases$ = this.store.select(state => state.lease.leases);

    this.leaseSubscription = this.leases$?.subscribe(
      data => {
        let res = data?.length > 0 ? data : undefined;
        if (res) {
          this.leases = res;
          this.addLeases(res);
          this.route.queryParams.subscribe(params => {
            this.leaseId = params['id'];
            if (this.leaseId)
              this.selectLease(this.leaseId);
          });
        }
        this.commonService.stopSpinner();
        if (this.leaseSubscription) {
          this.leaseSubscription.unsubscribe();
        }
      },
      error => {
        console.error('Error:', error);
        this.commonService.stopSpinner();
      }
    )

  }
  addLeases(res: LeaseStateModel[]): void {
    res.forEach(item => {
      if (!this.keyExists(item.leaseId, this.LeaseList)) {
        this.LeaseList.push({
          key: item.leaseId,
          value: `${item.leaseId} - ${item.vin.slice(-6)}`
        });
      }
    });
  }
  keyExists(key: any, itemList: { key: string, value: string }[]): boolean {
    return itemList.some(item => item.key === key);
  }

  selectedLeaseId: string = '';
  selectedLeaseDetails = new LeaseStateModel;
  onLeaseChange(value: string): void {
    this.commonService.startSpinner();
    this.selectedLeaseId = value;
    this.selectedLeaseDetails = this.leases.filter(lease => lease.leaseId === this.selectedLeaseId)[0];

    this.loadMaintenanceData(this.selectedLeaseDetails?.baseUnitId);
  }

  formatDate(_date: any): string | null {
    if (!_date)
      return "";
    // Parse the date string in UTC mode
    const date = new Date(_date.toString());
    // Format the date using Angular DatePipe, treating it as UTC
    return this.datePipe.transform(date, 'MM/dd/yyyy', 'UTC');
  }

  onSubmit() {
    if (!this.selectedLeaseId) {
      this.commonService.openSnackBar('Please select a lease', '', 'error');
      return;
    }
    if (this.documents.length > 0) {
      this.commonService.startSpinner();
      this.store.dispatch(new MaintenanceAction.UploadDocuments(this.documents[0], this.selectedLeaseDetails?.baseUnitId)).subscribe(
        (response: any) => {
          this.commonService.stopSpinner();
          let docResponse = response?.documents?.documents;
          if (docResponse?.success) {
            this.documents = [];
            this.commonService.openSnackBar('Files Upload successfully.', '', 'success');
          }            
          else {
            this.commonService.openSnackBar(docResponse.message, '', 'error');
          }
        },
        (error: HttpErrorResponse) => {
          this.commonService.stopSpinner();
          let httpErrRes = error.error?.message;
          if (!httpErrRes)
            httpErrRes = 'A technical error has occurred. Please reach out to the OTR Team for assistance.';          
          
          this.commonService.openSnackBar(httpErrRes, '', 'error');
        }
      );
    }
    else {
      //this.commonService.stopSpinner();
      this.commonService.openSnackBar("Please select file.", '', 'error')
    }
  }

}
