import { Component } from '@angular/core';

@Component({
  selector: 'app-truck-registration-info',
  templateUrl: './truck-registration-info.component.html',
  styleUrls: ['./truck-registration-info.component.css']
})
export class TruckRegistrationInfoComponent {

}
