import { Injectable, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Select, Store } from '@ngxs/store';
import { filter, map, mergeMap, Observable, of, switchMap, take, tap } from 'rxjs';
import { AuthActions } from '../states/auth/auth.actions';


@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(private store: Store,
    private readonly authService: AuthService,
    private route: ActivatedRoute) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    return this.authService.isLoading$.pipe(
      filter(isLoading => !isLoading),
      switchMap(() => {
        return this.authService.isAuthenticated$.pipe(
          switchMap(isAuthenticated => {
            if (isAuthenticated) {
              this.store.dispatch(new AuthActions.ApplyUserSession());
              return of(true);  
            } else {
              this.store.dispatch(new AuthActions.Login());
              return of(false);
            }
          })
        );
      })
    );
  }
}
