
<h1 class="form-title">
  Warranty Covered Components
</h1>

<div class="row justify-content-md-center mb-5">
  <div class="col-md-12 text-center">
    <h2>AVAILABLE TERM LENGTHS</h2>

    <button mat-stroked-button color="primary" type="button" class="months-btn">12 MONTHS</button><br />
    <button mat-stroked-button color="primary" type="button" class="months-btn">24 MONTHS</button><br />
    <button mat-stroked-button color="primary" type="button" class="months-btn">36 MONTHS</button><br />
    <button mat-stroked-button color="primary" type="button" class="months-btn">42 MONTHS</button><br />
    <button mat-stroked-button color="primary" type="button" class="months-btn">48 MONTHS</button>

  </div>
</div>

<div class="row justify-content-md-center mb-5">
  <div class="col-md-12 text-center">
    <h2>LIMITS OF LIABILITY</h2>
  </div>
</div>

<div class="row justify-content-md-center">
  <div class="col-md-12" style="max-width: 750px;">
    <div class="truck-flow-container">
      <img src="assets/images/truck-flow.png" class="truck-flow-img">
      <ul class="t-flow-ul">
        <li><span (click)="openDialog('ENGINE')">ENGINE $25,000</span></li>
        <li><span (click)="openDialog('TRANSMISSION')">TRANSMISSION $7,500</span></li>
        <li><span (click)="openDialog('DIFFERENTIAL(s)')">DIFFERENTIAL(s) $7,500</span></li>
        <li><span (click)="openDialog('AFTER TREATMENT')">AFTER TREATMENT $7,500</span></li>
        <li><span (click)="openDialog('ENGINE ACCESSORY')">ENGINE ACCESSORY $12,500</span></li>
        <li><span (click)="openDialog('ELECTRICAL')">ELECTRICAL $5,000</span></li>
      </ul>
    </div>
  </div>
</div>

<div class="row justify-content-md-center mt-5">
  <div class="col-md-12" style="max-width: 390px; position: relative">
    <img src="assets/images/coverage_scanbar.png" class="coverage-scanbar-img">
    <p class="scan-me-text">Scan Me for Coverage Info</p>
  </div>
</div>
