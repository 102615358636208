import { ChangeDetectorRef, Component } from '@angular/core';
import { Select, Store } from "@ngxs/store";
import { map, Observable } from "rxjs";
import { auth0User } from '../../states/auth/auth.model';
import { AuthState } from '../../states/auth/auth.state';
import { AuthActions } from '../../states/auth/auth.actions';



@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent {

  @Select(AuthState.CurrentUser) user$: Observable<auth0User> | undefined;
  @Select(AuthState.IsDone) done$: Observable<boolean> | undefined;
  @Select(AuthState.IsUserInactive) isUserInactive$: Observable<boolean>;

  constructor(private readonly store: Store) {
  }

  logout() {
    this.store.dispatch(new AuthActions.LogOut());
  }
  login() {
    this.store.dispatch(new AuthActions.Login());
  }
}
