
<h1 class="form-title">
  Report an Accident
</h1>

<div class="form-content">


  <div class="col-lg-12 mt-3">
    <div class="m-r-container">
      <div class="m-r-left-icons">1. Check for Safety:</div>
      <div class="drs-p">
        <ul class="w-ul">
          <li>Prioritize checking for any injuries among all parties involved. If there are injuries, call 911 immediately.</li>
          <li>Turn on hazard lights or set up flares to secure the scene.</li>
        </ul>
      </div>

      <br />
      <div class="m-r-left-icons">2. Call the Police:</div>
      <div class="drs-p">
        <ul class="w-ul">
          <li>Contact the police to get a report, especially if there's significant damage or injuries. Note that police may not respond on private property.</li>
          <li>For commercial insurance claims, police reports are mandatory, so collect a copy of that report.</li>
        </ul>
      </div>

      <br />
      <div class="m-r-left-icons">3. Exchange Information:</div>
      <div class="drs-p">
        <ul class="w-ul">
          <li>Collect names, addresses, telephone numbers, and driver's license numbers from all drivers.</li>
          <li>Collect Insurance information! (insurance carrier, policy #, date of coverage, insurance carrier contact info)</li>
          <li>Verify this information with their driver's licenses and vehicle registrations.</li>
          <li>Note license plate numbers and vehicle identification numbers.</li>
        </ul>
      </div>

      <br />
      <div class="m-r-left-icons">4. Document the Scene:</div>
      <div class="drs-p">
        <ul class="w-ul">
          <li>Take photos of the accident, including damages, the overall scene, traffic controls, and any visual obstacles.</li>
          <li>If possible, make a quick sketch of the accident layout.</li>
        </ul>
      </div>

      <br />
      <div class="m-r-left-icons">5. Witness Details:</div>
      <div class="drs-p">
        <ul class="w-ul">
          <li>Obtain contact information from any witnesses and passengers involved.</li>
        </ul>
      </div>

      <br />
      <div class="m-r-left-icons">6. Leave a Note:</div>
      <div class="drs-p">
        <ul class="w-ul">
          <li>If property damage occurs and the owner is not around, leave your contact information in a visible spot.</li>
        </ul>
      </div>

      <br />
      <div class="m-r-left-icons">7. Notify Your Insurance and OTR Leasing:</div>
      <div class="drs-p">
        <ul class="w-ul">
          <li>Contact your insurance company and get a claim# along with the Point of Contact for follow-up on the claim.</li>
        </ul>
      </div>

      <br />
      <div class="m-r-left-icons">8. Contact OTR Leasing:</div>
      <div class="drs-p">
        <span>
          Contact OTR Leasing immediately to report the accident and provide us with the claim#, Point of Contact info with the insurance company, date
          of accident, and whether Insurance think sthe claim is a total loss or repairable.
        </span>

      <div class="ms-4">
        <p class="m-r-left-icons mt-4 mb-0"><b>Technical Contact</b></p>
        <p class="paragraph-tag"><b>Email</b>: <a href="mailto:customerservice@otrleasing.com">customerservice@otrleasing.com</a></p>
        <p class="paragraph-tag"><b>Phone</b>: <a href="tel:(877) 440-6543">(877) 440-6543</a></p>
      </div>

      </div>
    </div>
  </div>


</div>
