import { FileDTO } from "./upload-documents.model";

export namespace MaintenanceAction {

  export class UploadDocuments {
    static readonly type = '[Maintenance] Upload Documents';
    constructor(public file: FileDTO, public baseUnitid: number) { }
  }

  export class GetMaintenance {
    static readonly type = '[Maintenance] Get Maintenance';

    constructor(public baseUnitid: number) { }
  }

}
