import { Action, Select, State, StateContext } from '@ngxs/store';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpServiceService } from '../../services/http-service.service';
import { environment } from '../../../environments/environment';
import { CommonService } from '../../services/common.service';
import { MaintenanceAction } from './upload-documents.actions';
import { FileDTO, UploadDocumentsStateModel } from './upload-documents.model';
import { CustomerState } from '../customer/customer.state';


export interface IUploadDocument {
  documents: any | null;
  maintenance: any | null;
}

@Injectable()
@State<IUploadDocument>({
  name: 'documents',
  defaults: {
    documents: [],
    maintenance: null
  }
})


export class UploadDocumentsState {
  baseUrl: string;
  @Select(CustomerState.GetCustomerId) customer$: Observable<string>
  constructor(private http: HttpServiceService,
    private commonService: CommonService) {
    this.baseUrl = environment.lease_path.base_uri;
  }

  @Action(MaintenanceAction.GetMaintenance)
  async GetMaintenanceData(ctx: StateContext<IUploadDocument>, action: MaintenanceAction.GetMaintenance) {
    
    let baseUnitid = action.baseUnitid;   

    const url = `${this.baseUrl}Maintenance/current/${baseUnitid}`;
    this.http.getDataWithToken(url).pipe(
      tap((response: any) => {
        //response = { staus: "ok", dueDate: "2024-06-11T00:00:00Z" };
        ctx.patchState({
          maintenance: response
        });
      }),
      catchError((error: any) => {
        console.error('Error while fetching account api:', error);
        return throwError(error);
      })
    ).subscribe();


  }

  @Action(MaintenanceAction.UploadDocuments)
  async uploadDocuments(ctx: StateContext<any>, action: MaintenanceAction.UploadDocuments): Promise<any> {

    let baseUnitid = action.baseUnitid;
    const customerId = await this.commonService.getLastValidValue(this.customer$);
    if (customerId == undefined)
      return

    const file = action.file;  
    // Prepare FormData
    const formData = this.prepareFormData(file, customerId, baseUnitid);
 
    // Upload documents
    const url = `${this.baseUrl}Maintenance/upload`;
    return this.http.uploadDocs(url, formData).pipe(
      tap((response: any) => {
      
        ctx.setState({
          documents: response
        });
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  private prepareFormData(file: FileDTO, customerId: any, baseUnitid: any): FormData {
    const formData = new FormData();    
    if (file.file instanceof Blob) {
      formData.append(`File`, file.file, file.file.name);
      formData.append(`CustomerId`, customerId);
      formData.append(`BaseUnitId`, baseUnitid);
      } else {
      console.error('File is not of type Blob:', file.file);
      }
    
    return formData;
  }
  
}
