
export interface IMessageViewStateModel {
    loading: boolean;
    busy: boolean;
    icon: string;
    message: string
  }

export type CustomMessageKey = "ExpiredInvitation" | "NotFound" | "MissingInvitationId"
export const CustomMessageKeys: Record<CustomMessageKey, CustomMessageKey> = {
  ExpiredInvitation: "ExpiredInvitation",
  NotFound: "NotFound",
  MissingInvitationId: "MissingInvitationId"
}
export type MessageConfig = {icon: string,  message: string};
export const CustomMessageDisplayConfig: Record<CustomMessageKey, MessageConfig> = {
  ExpiredInvitation: {icon: 'warning', message: 'The Invitation has expired or is no longer active.'},
  NotFound: {icon: 'warning', message: 'The requested resource was not found.'},
  MissingInvitationId: {icon: 'warning', message: 'The invitation id is missing.'}
}

