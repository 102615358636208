import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { CommonService } from '../../../../../services/common.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxGpAutocompleteDirective } from '@angular-magic/ngx-gp-autocomplete';
import { Observable, Subscription } from 'rxjs';
import { LeaseStateModel } from '../../../../../states/lease/lease.model';
import { LeaseAction } from '../../../../../states/lease/lease.actions';
import { AccountsInformationDialogComponent } from '../accounts-information-dialog/accounts-information-dialog.component';
import { AccountAction } from '../../../../../states/account/account.actions';
import { AccountStateModel } from '../../../../../states/account/account.model';

@Component({
  selector: 'app-accounts-information',
  templateUrl: './accounts-information.component.html',
  styleUrls: ['./accounts-information.component.css']
})
export class AccountsInformationComponent {

  data: FormGroup;
  stateList: { key: string, value: string }[] = [
    { key: 'AL', value: 'Alabama' },
    { key: 'AK', value: 'Alaska' },
    { key: 'AZ', value: 'Arizona' },
    { key: 'AR', value: 'Arkansas' },
    { key: 'CA', value: 'California' },
    { key: 'CO', value: 'Colorado' },
    { key: 'CT', value: 'Connecticut' },
    { key: 'DE', value: 'Delaware' },
    { key: 'FL', value: 'Florida' },
    { key: 'GA', value: 'Georgia' },
    { key: 'HI', value: 'Hawaii' },
    { key: 'ID', value: 'Idaho' },
    { key: 'IL', value: 'Illinois' },
    { key: 'IN', value: 'Indiana' },
    { key: 'IA', value: 'Iowa' },
    { key: 'KS', value: 'Kansas' },
    { key: 'KY', value: 'Kentucky' },
    { key: 'LA', value: 'Louisiana' },
    { key: 'ME', value: 'Maine' },
    { key: 'MD', value: 'Maryland' },
    { key: 'MA', value: 'Massachusetts' },
    { key: 'MI', value: 'Michigan' },
    { key: 'MN', value: 'Minnesota' },
    { key: 'MS', value: 'Mississippi' },
    { key: 'MO', value: 'Missouri' },
    { key: 'MT', value: 'Montana' },
    { key: 'NE', value: 'Nebraska' },
    { key: 'NV', value: 'Nevada' },
    { key: 'NH', value: 'New Hampshire' },
    { key: 'NJ', value: 'New Jersey' },
    { key: 'NM', value: 'New Mexico' },
    { key: 'NY', value: 'New York' },
    { key: 'NC', value: 'North Carolina' },
    { key: 'ND', value: 'North Dakota' },
    { key: 'OH', value: 'Ohio' },
    { key: 'OK', value: 'Oklahoma' },
    { key: 'OR', value: 'Oregon' },
    { key: 'PA', value: 'Pennsylvania' },
    { key: 'RI', value: 'Rhode Island' },
    { key: 'SC', value: 'South Carolina' },
    { key: 'SD', value: 'South Dakota' },
    { key: 'TN', value: 'Tennessee' },
    { key: 'TX', value: 'Texas' },
    { key: 'UT', value: 'Utah' },
    { key: 'VT', value: 'Vermont' },
    { key: 'VA', value: 'Virginia' },
    { key: 'WA', value: 'Washington' },
    { key: 'WV', value: 'West Virginia' },
    { key: 'WI', value: 'Wisconsin' },
    { key: 'WY', value: 'Wyoming' }
  ]
  maskPhonePattern = '(000)000-0000';
  isReadOnly: boolean = true;

  leases$: Observable<LeaseStateModel[]>;
  leaseSubscription: Subscription;
  leaseData: LeaseStateModel = new LeaseStateModel();

  account$: Observable<AccountStateModel>;
  accountSubscription: Subscription;

  constructor(private store: Store,
    private router: Router,
    private commonService: CommonService,
    private spinner: NgxUiLoaderService,
    public dialog: MatDialog) {

    this.store.dispatch(new LeaseAction.GetLeasesData());
    this.leases$ = this.store.select(state => state.lease.leases);

    this.store.dispatch(new AccountAction.GetData());
    this.account$ = this.store.select(state => state.account.account);
  }

  ngOnInit(): void {
    this.commonService.startSpinner();
    this.loadAccountData();
   //this.loadLeaseData();
    this.loadFormControls();
  }

  ngOnDestroy() {
    if (this.accountSubscription) {
      this.accountSubscription.unsubscribe();
    }
    if (this.leaseSubscription) {
      this.leaseSubscription.unsubscribe();
    }
  }

  loadAccountData() {

    this.accountSubscription = this.account$?.subscribe(
      data => {
        
        if (data) {
          this.data?.patchValue({
            firstName: data.firstName,
            lastName: data.lastName,
            address1: data.address1,
            state: data.state,
            zipcode: data.zipCode,
            city: data.city,
            mobilePhone: data.phone,
            email: data.email,
          });
        }
        this.commonService.stopSpinner();
      },
      error => {
        this.commonService.stopSpinner();
        console.error('Error:', error);
      }
    )
  }

  loadLeaseData() {

    const leaseIdToFind = '1836-008';
    this.leaseSubscription = this.leases$?.subscribe(
      data => {
        //let res = data?.find(x => x.leaseId === leaseIdToFind);
        let res = data?.length > 0 ? data[0] : undefined;
        if (res) {
          this.leaseData = res;
          this.data?.patchValue({
            firstName: res.firstName,
            lastName: res.businessName,
            address1: res.address1,
            address2: res.address1,
            state: res.state,
            zipcode: res.postalCode,
            city: res.city,
            mobilePhone: res.phoneNumber,
            email: res.emailAddress,
          });
        }
        this.commonService.stopSpinner();
      },
      error => {
        this.commonService.stopSpinner();
        console.error('Error:', error);
      }
    )
  }

  loadFormControls() {

    this.data = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      address1: new FormControl('', [Validators.required]),
      address2: new FormControl(''),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      zipcode: new FormControl('', [Validators.required]),
      //mobilePhone: new FormControl('', [Validators.required, Validators.pattern('^[0-9]+$'), Validators.maxLength(10), Validators.minLength(10)]),
      mobilePhone: new FormControl('', [Validators.required, Validators.minLength(10)]),
      email: new FormControl('', [Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$'), Validators.required]),
    });

    this.disableControls();
  }

  disableControls() {
    this.data?.get('firstName')?.disable();
    this.data?.get('lastName')?.disable();
    this.data?.get('address1')?.disable();
    this.data?.get('address2')?.disable();
    this.data?.get('city')?.disable();
    this.data?.get('state')?.disable();
    this.data?.get('zipcode')?.disable();
    this.data?.get('mobilePhone')?.disable();
    this.data?.get('email')?.disable();
  }

  getErrorMessage(dataObject: any) {
    if (dataObject != null) {
      if (this.data.get(dataObject)?.hasError('required')) {
        return `You must enter a value`;
      } else if (this.data.get(dataObject)?.hasError('email')) {
        return `You must enter a valid value`;
      } else if (this.data.get(dataObject)?.hasError('numberField')) {
        return `You must enter a valid value`;
      } else if (this.data.get(dataObject)?.hasError('pattern')) {
        return `You must enter a valid value`;
      } else if (this.data.get(dataObject)?.hasError('maxlength')) {
        return `You must enter a max valid length`;
      } else if (this.data.get(dataObject)?.hasError('minlength')) {
        return `You must enter a min valid length`;
      } else if (this.data.get(dataObject)?.hasError('max')) {
        return `You must enter a max valid number`;
      }
    }
    return '';
  }

  options = {
    // Define your autocomplete options here
    componentRestrictions: {
      country: ['US'],
    },
  };

  @ViewChild('ngxPlaces') placesRef: any = NgxGpAutocompleteDirective;

  public handleAddressChange(place: google.maps.places.PlaceResult) {
    console.log(place);
    // Do some stuff
    let addressfullvalue = place?.formatted_address;
    let addressComponents = place?.address_components as any[];
    if (!addressComponents) addressComponents = [];

    const { street, city, zip, state, fullAddress, shortAddress } =
      this.getAddressDetails(addressComponents);

    // Address fields
    this.data.patchValue({
      address: shortAddress,
      state: state,
      zipcode: zip,
      city: city,
    });
  }

  getAddressDetails(addressComponents: any[]) {
    let street = '';
    let city = '';
    let zip = '';
    let state = '';
    let fullAddress = '';
    let shortAddress = '';

    addressComponents.forEach((component) => {
      if (component.types.includes('street_number')) {
        street += component.long_name + ' ';
        shortAddress += component.short_name + ' ';
      } else if (component.types.includes('route')) {
        street += component.long_name;
        shortAddress += component.short_name;
      } else if (
        component.types.includes('locality') ||
        component.types.includes('sublocality') ||
        component.types.includes('sublocality_level_1') ||
        component.types.includes('postal_town')
      ) {
        city = component.long_name;
      } else if (component.types.includes('postal_code')) {
        zip = component.long_name;
      } else if (component.types.includes('administrative_area_level_1')) {
        state = component.short_name;
      }

      // Concatenate all components for full address
      fullAddress += component.long_name + ', ';
    });

    return { street, city, zip, state, fullAddress, shortAddress };
  }


  onSubmit() {
    if (this.data.invalid)
      return;
    this.commonService.startSpinner();
    this.postAccountData();
    //this.openDialog();
    //this.disableControls();
    this.isReadOnly = true;
  }
  postAccountData() {
    
    this.data?.get('firstName')?.enable();
    this.data?.get('lastName')?.enable();
    let accountData = {
      firstName: this.data.value?.firstName || '',
      lastName: this.data.value?.lastName || '',
      address1: this.data.value?.address1 || '',
      address2: this.data.value?.address2 || '',
      city: this.data.value?.city || '',
      state: this.data.value?.state || '',
      zipCode: this.data.value?.zipcode || '',
      phone: this.data.value?.mobilePhone || '',
      email: this.data.value?.email || ''
    } as AccountStateModel;

    try {
      this.store.dispatch(new AccountAction.AddAccount(accountData)).subscribe(
        (response: any) => {
         
          let res = response.account.accountReponse
          if (res) {
            //this.data.reset();
            this.disableControls();
            this.openDialog();
          }          
          //this.commonService.openSnackBar('Message here.', '', 'error');
          this.commonService.stopSpinner();
        },
        (error: any) => {
          this.commonService.openSnackBar('A technical error has occurred. Please reach out to the OTR Team for assistance.', '', 'error');
          this.commonService.stopSpinner();
        }
      );

    } catch (error: any) {
      this.commonService.openSnackBar(error.message, '', 'error');
      this.commonService.stopSpinner();
    }
  }

  openDialog(): void {

    const dialogRef = this.dialog.open(AccountsInformationDialogComponent, {
      width: "690px",
      autoFocus: false,
      enterAnimationDuration: '500ms',
      exitAnimationDuration: '1000ms',
      position: {
        top: '15%',
      },
      data: {
        leaseData: this.leaseData,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');

    });
    this.commonService.stopSpinner();
  }

  onEdit() {
    this.isReadOnly = false;
    this.data?.get('address1')?.enable();
    this.data?.get('address2')?.enable();
    this.data?.get('city')?.enable();
    this.data?.get('state')?.enable();
    this.data?.get('zipcode')?.enable();
    this.data?.get('mobilePhone')?.enable();
    this.data?.get('email')?.enable();
  }

  back() {
    this.router.navigate(['/dashboard'], {
      skipLocationChange: true,
    });
  }

}
