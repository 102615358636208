
<div class="row">
  <div class="col-lg-12" style="width: calc(100% - 300px);">
    <h1 class="form-title">
      Claims Procedures
    </h1>
    <p class="h-d-txt">
    </p>
  </div>
  <div class="col-lg-4" style="max-width: 300px">
    <div class="p-r">
      <mat-icon class="left-icons-e-f orange" fontIcon="phone_in_talk"></mat-icon>
      <span class="drs-email-phone-element" style="text-decoration: none"><a href="tel:888-806-9360">888-806-9360</a></span>
    </div>
    <div class="p-r">
      <mat-icon class="left-icons-e-f orange" fontIcon="mail"></mat-icon>
      <span class="drs-email-phone-element email-link"><a href="mailto:claims@total360.com">claims@total360.com</a></span>
    </div>
  </div>
</div>

<div class="form-content">
  <br />
  <div class="row">
    <div class="col-lg-12 mb-4">
      <h2 class="blue subtitle"><span class="as-tital-icon">>>></span> If a breakdown occurs, please call Total360 first. <span class="as-tital-icon"><<<</span></h2>
    </div>
    <!--<div class="col-lg-12 mt-3">
      <img src="assets/images/claim-content1.png" />
    </div>-->

    <div class="col-lg-12 mt-3 text-center">

      <div class="inner-div" style="display: inline-block;">

        <div class="WCP-detail">
          <img src="assets/images/CP_Breakdown.png" class="CP-icons-img">
          <div class="WCP-title">BREAKDOWN OCCURS</div>
          <mat-icon class="light-blue WCP-right-icon" fontIcon="trending_flat"></mat-icon>
          <mat-icon class="light-blue WCP-bottom-icon" fontIcon="trending_flat"></mat-icon>
          <div class="WCP-sub-title">TAKE OR TOW YOUR VEHICLE TO THE NEAREST LICENSED REPAIR FACILITY</div>
        </div>

        <div class="WCP-detail">
          <img src="assets/images/CP_Customer.png" class="CP-icons-img">
          <div class="WCP-title">CUSTOMER AUTHORIZES DIAGNOSTICS AND TEARDOWN ONLY</div>
          <mat-icon class="light-blue WCP-right-icon" fontIcon="trending_flat"></mat-icon>
          <mat-icon class="light-blue WCP-bottom-icon" fontIcon="trending_flat"></mat-icon>
          <div class="WCP-sub-title">THEN PROVIDES ANY MAINTENANCE RECORDS</div>
        </div>

        <div class="WCP-detail">
          <img src="assets/images/CP_Repair.png" class="CP-icons-img">
          <div class="WCP-title">
            YOUR REPAIR FACILITY WILL PROVIDE:
            <ul>
              <li>ESTIMATE</li>
              <li>ECM REPORT</li>
              <li>PICTURES</li>
            </ul>
          </div>
          <mat-icon class="light-blue WCP-right-icon" fontIcon="trending_flat"></mat-icon>
        </div>

        <div class="WCP-detail">
          <img src="assets/images/CP_Process.png" class="CP-icons-img">
          <div class="WCP-title">TOTAL360 WILL PROCESS THE CLAIM</div>
          <mat-icon class="light-blue WCP-right-icon" fontIcon="trending_flat"></mat-icon>
          <mat-icon class="light-blue WCP-bottom-icon" fontIcon="trending_flat"></mat-icon>
          <div class="WCP-sub-title">THEN CALL YOU WITH THE DECISION</div>
        </div>

        <div class="WCP-detail" style="padding-right: 0px;">
          <!--<mat-icon class="WCP-main-icon light-blue" fontIcon="account_balance_wallet"></mat-icon>-->
          <img src="assets/images/CP_Paid.png" class="CP-icons-img">
          <div class="WCP-title" style="max-width: 200px;">
            APPROVED CLAIMS WILL BE PAID TO YOUR REPAIR FACILITY ONCE TOTAL360 RECEIVES:
            <ul>
              <li>FINAL INVOICE</li>
              <li>PARTS AND LABOR</li>
              <li>POST REPAIR ECM</li>
              <li>PAYMENT VERIFICATION</li>
            </ul>
          </div>
        </div>

      </div>
    </div>

    <hr />

    <div class="col-lg-12 mt-3">

      <div class="row">
        <div class="col-lg-6">

          <div class="m-r-container">
            <div class="m-r-left-icons">1</div>
            <p class="drs-p">
              <b class="text-decoration-underline">ARRIVING AT THE REPAIR FACILITY</b>
              <br />
              The customer must authorize the repair facility to perform any diagnostics/teardown ONLY.
            </p>
          </div>

          <div class="m-r-container">
            <div class="m-r-left-icons">2</div>
            <div class="drs-p">
              <b class="text-decoration-underline">REPAIR FACILITY MUST PROVIDE:</b>
              <br />
              <b>ESTIMATE</b>
              <br />
              <ul class="w-ul">
                <li>Last 8 of VIN</li>
                <li>Current odometer/hour and ECM mileage</li>
                <li>Detailed tech notes explaining customer complaint, detailed cause of failure & action needed to correct</li>
                <li>Part breakdown including individual part number and cost, quantity, shop supplies, fluids, tax, and other fees</li>
                <li>Labor breakdown by job, number of hours, and rate</li>
              </ul>
              <br />
              <b>ECM FAULT CODE HISTORY REPORT*</b>
              <br />
              <span>Before clearing codes for diagnostics. Must include:</span>
              <br />
              <ul class="w-ul">
                <li>Engine serial number (ESN)/VIN</li>
                <li>Recent timestamp</li>
                <li>First & last occurrences of active/inactive codes</li>
              </ul>
              <br />
              <b>PICTURES OF THE FAILED COMPONENT(S)</b>
              <br />
              <span>Showing visible damage</span>
              <br />
              <br />
              <b>MAINTENANCE RECORDS</b>
              <br />
              <span>From start of agreement to current (if not already submitted)</span>

            </div>
          </div>

        </div>
        <div class="col-lg-6">

          <div class="m-r-container mt-3">
            <div class="m-r-left-icons">3</div>
            <div class="drs-p">
              <b class="text-decoration-underline">TOTAL360 WILL PROCESS THE CLAIM</b>
              <br />
              <span>Once all required information is received, Total360 will adjudicate the claim.</span>
              <br />
              <ul class="w-ul">
                <li>Total360 must authorize repairs before the repair facility can begin work</li>
                <li>All diagnostic information must be received within fifteen (15) days of the claim start date</li>
                <li>The repair facility has ten (10) business days once Total360 makes authorization to complete repairs</li>
              </ul>
            </div>
          </div>

          <div class="m-r-container mt-3">
            <div class="m-r-left-icons">4</div>
            <div class="drs-p">
              <b class="text-decoration-underline">AFTER REPAIRS, PREFORM A ROAD TEST AND PROVIDE AN INVOICE</b>
              <br />
              <span>One we receive the following, we send prompt payment to the repair facility</span>
              <br />
              <ul class="w-ul">
                <li>Date of repair</li>
                <li>Last 8 of VIN</li>
                <li>Mileage/hour and ECM mileage reading</li>
                <li>Finalized part/labor breakdown detailing completed repairs</li>
                <li>Parts & labor warranty terms (ex 1yr/100K miles)</li>
                <li>Payment receipt for any non-covered balance</li>
              </ul>
            </div>
          </div>
          <div class="mt-3">
            <img src="assets/images/Scanbar.png" class="scan-code">
          </div>

        </div>
      </div>

    </div>


  </div>

</div>

<img src="assets/images/total360.png" class="total360-logo">

