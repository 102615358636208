import { Action, NgxsOnInit, Select, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { HttpServiceService } from '../../services/http-service.service';
import { Observable, catchError, map, tap, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LeaseAction } from './lease.actions';
import { LeaseStateModel, LeasesStateModel } from './lease.model';
import { CustomerState } from '../customer/customer.state';

@State<LeaseStateModel>({
  name: 'lease',
  defaults: <LeaseStateModel>{
  }
})

@Injectable()

export class LeaseState {
  baseUrl: string;

  @Select(CustomerState.GetCustomerId) customer$: Observable<string>
  constructor(
    private http: HttpServiceService) {

    this.baseUrl = environment.lease_path.base_uri;
  }


  @Action(LeaseAction.GetData)
  onGetLeaseData(ctx: StateContext<LeaseStateModel>, { leaseId }: { leaseId: string }) {

    this.customer$.subscribe(id => {

      if (id == undefined)
        return;

      //Lease Api 
      const url = `${this.baseUrl}Customers/${id}/leases`;
      return this.http.getDataWithToken(url).pipe(
        tap((response: LeaseStateModel[]) => {
          var lease = response.find(x => x.leaseId === leaseId);
          ctx.patchState({
            lease: lease
          });
        }),
        catchError((error: any) => {
          console.error('Error while fetching lease api:', error);
          return throwError(error);
        })
      ).subscribe();

    });


  }


  @Action(LeaseAction.GetLeasesData)
  onGetLeasesData(ctx: StateContext<LeasesStateModel>) {

    this.customer$.subscribe(id => {


      if (id == undefined)
        return;

      // //Lease Api

      const url = `${this.baseUrl}Customers/${id}/leases`;
      return this.http.getDataWithToken(url).pipe(
        tap((response: LeaseStateModel[]) => {

          ctx.patchState({
            leases: response
          });
        }),
        catchError((error: any) => {
          console.error('Error while fetching lease api:', error);
          return throwError(error);
        })
      ).subscribe();

    });
  }
}
