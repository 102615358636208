export class ContactStateModel {
  leaseId: number;
  issueTypeId: number;
  message: string;
  createdBy: string;
}

export class ContactResponseModel {
  success: boolean;
  responsecode: number;
  statusMessage: string;
  transactionId: string;
  approvalcode: string;
  approvalMessage: string;
  cscResponse: string;
  avsResponse: string;
  externaltransactionId: string;
  maskedCardNumber: string;
  errors: string[];
  total: number;
  invoice: string;
  customerName: string;
}




