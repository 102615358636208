import { Action, Select, State, StateContext, Store } from '@ngxs/store';
import { Observable, catchError, tap, throwError, map, of, switchMap, first, finalize } from 'rxjs';
import { Injectable } from '@angular/core';
import { CustomerPaymentStateModel, PaymentResponseModel, PaymentStateModel } from './payment.model';
import { HttpServiceService } from '../../services/http-service.service';
import { environment } from '../../../environments/environment';
import { AddPayment, PaymentAction } from './payment.actions';
import { HttpHeaders } from '@angular/common/http';
import { AuthActions } from '../auth/auth.actions';
import { AuthState } from '../auth/auth.state';
import { CustomerState } from '../customer/customer.state';

export interface IPaymentStateModel {
  payment: PaymentResponseModel | null;
}

@Injectable()
@State<IPaymentStateModel>({
  name: 'payment',
  defaults: {
    payment: null
  }
})

export class PaymentState {
  baseUrl: string;
  authorizedKey: string;

  @Select(CustomerState.GetCustomerId) customer$: Observable<string>
  constructor(private http: HttpServiceService,
              private store: Store)
  {
    this.baseUrl = environment.lease_path.base_uri;
    this.authorizedKey = environment.lease_path.key;
  }
  @Action(AddPayment)
  addPayment(ctx: StateContext<IPaymentStateModel>, action: AddPayment): Observable<any> {

      let url = `${this.baseUrl}Paytrace/payment`;
      return this.http.postDataWithToken(url, action.payload).pipe(
          tap((payment: any) => {
            ctx.patchState({
              payment: payment
            });
          }),
          catchError((error) => {
            return throwError(error);
          })
        );
     
  }


  @Action(PaymentAction.GetData)
  onGetPaymentData(ctx: StateContext<CustomerPaymentStateModel>,{ leaseId }: { leaseId: string }) {

    this.customer$.subscribe(id => {

      if (id == undefined)
        return;

      // Payment Api

      const url = `${this.baseUrl}Customers/${id}/payments`;
      this.http.getDataWithToken(url).pipe(
        tap((response: CustomerPaymentStateModel[]) => {
          var payment = response.find(x => x.leaseId === leaseId);
          ctx.patchState({
            id: payment?.id,
            leaseId: payment?.leaseId,
            customerId: payment?.customerId,
            nextDueDate: payment?.nextDueDate,
            pastDueAmount: payment?.pastDueAmount,
            paymentDue: payment?.paymentDue,
            leaseAutoDebitInd: payment?.leaseAutoDebitInd,
            satisfiedDate: payment?.satisfiedDate,
          });
        }),
        catchError((error: any) => {
          console.error('Error while fetching payments api:', error);
          return throwError(error);
        })
      ).subscribe();

    });
  }
}
