export interface IActivateCustomer {
  customerId: string;
  inviteId: string;
  activationStatus: boolean;
}

export class RequestActivateCustomer{
  token: string;
  auth0UserId: string;

  constructor(token: string, auth0UserId: string) {
    this.token = token;
    this.auth0UserId = auth0UserId;
  }
}
export type StatusType = {
  status: boolean;
}
