import { Component } from '@angular/core';

@Component({
  selector: 'app-insurance-requirements',
  templateUrl: './insurance-requirements.component.html',
  styleUrls: ['./insurance-requirements.component.css']
})
export class InsuranceRequirementsComponent {

}
