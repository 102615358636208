export namespace LeaseAction {
   export class GetData {
     static readonly type = '[lease] Get Data';
     constructor(public leaseId: string) { }
  }

  export class GetLeasesData {
    static readonly type = '[leases] Get Data';
  }
}

