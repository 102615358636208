import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-accounts-information-dialog',
  templateUrl: './accounts-information-dialog.component.html',
  styleUrls: ['./accounts-information-dialog.component.css']
})
export class AccountsInformationDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<AccountsInformationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

  }

  onNoClick(): void {
    this.dialogRef.close();
  } 

}
