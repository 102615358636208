<mat-card class="mat-elevation-z8">
  <ng-container *ngIf="messageConfig$ | async as messageConfig">
  <div class="icon-container">
    <ng-container *ngIf="messageConfig.icon as icon">
      <mat-icon color="warn" class="icon">{{ icon }}</mat-icon>
    </ng-container>
  </div>
  <div class="message-container">
    <h3>{{messageConfig.message}} <a class="d-l-link" (click)="navigateToHome()">Continue to home page.</a></h3>

  </div>
  </ng-container>
</mat-card>
