import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-limits-of-liability-detail-dialog',
  templateUrl: './limits-of-liability-detail-dialog.component.html',
  styleUrls: ['./limits-of-liability-detail-dialog.component.css']
})
export class LimitsOfLiabilityDetailDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<LimitsOfLiabilityDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {    

  }

  onNoClick(): void {
    this.dialogRef.close();
  } 


}
