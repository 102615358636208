export namespace CustomerActions{

  export class SetInviteId {
    static readonly type = '[SignUp] SetInviteId';

    constructor(public inviteId: string) { }
  }

  export class GetActiveCustomer {
    static readonly type = '[Customer] GetActiveCustomer';
    constructor(public userId: string, public inviteId: string) { }
  }
  export class GetActivationTokenStatus {
    static readonly type = '[Customer] GetActivationTokenStatus';
    constructor(public token: string) { }
  }

  export class GetCustomerId {
    static readonly type = '[Customer] GetCustomerId';

    constructor(public userId: string) { }
  }
}
