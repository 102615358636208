import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Observable, Subject, filter, firstValueFrom, scan, tap } from 'rxjs';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SnackBarComponent } from '../views/main/components/snack-bar/snack-bar.component';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  amount: any;
  private renderer: Renderer2;

  constructor(private router: Router, private _snackBar: MatSnackBar, private spinner: NgxUiLoaderService, rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);

  }

  private progresBarDt = new Subject<any>();

  sendUpdate(message: string) {
    this.progresBarDt.next({ text: message });
  }

  getUpdate(): Observable<any> {
    return this.progresBarDt.asObservable();
  }
  getUrlParameter(name: string, url: string): string | null {
    const searchParams = new URLSearchParams(new URL(url).search);
    return searchParams.get(name);
  }

   openSnackBar(message: string, action: string, type: string) {
    const configSuccess = {
      horizontalPosition: 'center' as MatSnackBarHorizontalPosition,
      verticalPosition: 'top' as MatSnackBarVerticalPosition,
      panelClass: ['custom-snackbar', 'cs-success'],
      duration: 10000,
      data: {
        title: 'Success',
        icon: 'done', 
        //items: ['Item 1', 'Item 2', 'Item 3']
        message: message
      }
     };
     const configError = {
       horizontalPosition: 'center' as MatSnackBarHorizontalPosition,
       verticalPosition: 'top' as MatSnackBarVerticalPosition,
       panelClass: ['custom-snackbar','cs-error'],
       duration: 10000,
       data: {
         title: 'Error',
         icon: 'gpp_maybe', 
         //items: ['Item 1', 'Item 2', 'Item 3']
         message: message
       }
     };

     let config: any;
     if (type === 'success')
       config = configSuccess;
     else
       config = configError;

     const snackBarRef = this._snackBar.openFromComponent(SnackBarComponent, config);
  }



  startSpinner() {
    this.spinner.start();
  }
  stopSpinner() {
    this.spinner.stop();
  }

  scrollRestoration() {
    document.querySelector('.content')?.scrollTo(0, 0);
  }


  goTo404Page() {
    this.router.navigate(['/invalid'], {
      //skipLocationChange: true,
    });
  }

  setWrapperDisplay(displayValue: string): void {
    const wrapperElement = document.querySelector('.wrapper');
    if (wrapperElement) {
      this.renderer.setStyle(wrapperElement, 'display', displayValue);
    } 
  }
  setDefaultLoadingDisplay(displayValue: string): void {
    const defaultLoadElement = document.querySelector('.default-loading-container');
    if (defaultLoadElement)
      this.renderer.setStyle(defaultLoadElement, 'display', displayValue);
  }

  async getLastValidValue(observable$: Observable<any>): Promise<any | undefined> {
    return firstValueFrom(
      observable$.pipe(
        // Scan to keep a count of the emissions and store the last valid value
        scan((acc, value) => {
          acc.count += 1;
          if (value !== undefined) {
            acc.lastValidValue = value;
          }
          return acc;
        }, { count: 0, lastValidValue: undefined as any | undefined }),
        // Filter to ensure only valid values are processed
        filter(acc => acc.lastValidValue !== undefined), // Modify this condition as per your needs
        // Get the last valid value after the filter
        tap(acc => {
          if (acc.lastValidValue === undefined) {
            console.warn('All emissions are undefined.');
            return acc.lastValidValue;
          }
        })
      )
    ).then(acc => acc.lastValidValue);
  }
  

}
