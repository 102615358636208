
<h1 class="form-title">
  Make A Payment
</h1>

<div class="form-content make-a-payment-content">

  <form class="example-form" [formGroup]="data" (ngSubmit)="onSubmit()">

    <div class="row">
      <div class="col col-lg-12">
        <p class="">
          Simply enter the information below, press ‘Submit Payment’, and then you’re all done! Any questions please contact Customer Service at (877) 440-6543.
        </p>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-lg-6 col-md-6 col-sm-6 mb-3">
        <mat-label>Last 6 Of VIN Number <span class="required-lable-txt">(Required)</span></mat-label>
        <mat-form-field class="example-full-width">
          <input matInput placeholder="" formControlName="lastVinNumber">
          <mat-error>{{getErrorMessage("lastVinNumber")}}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 mb-3">
        <mat-label>Payment Amount <span class="required-lable-txt">(Required)</span></mat-label>
        <mat-form-field class="example-full-width">
          <input matInput placeholder="" formControlName="paymentAmount">
          <mat-error>{{getErrorMessage("paymentAmount")}}</mat-error>
        </mat-form-field>
      </div>

      <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
        <mat-label>Credit Card Number <span class="required-lable-txt">(Required)</span></mat-label>
        <mat-form-field class="example-full-width">
          <input matInput placeholder="" formControlName="creditCardNumber">
          <mat-error>{{getErrorMessage("creditCardNumber")}}</mat-error>
        </mat-form-field>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-6 mb-3">
        <mat-label>Month <span class="required-lable-txt">(Required)</span></mat-label>
        <mat-form-field class="example-full-width">
          <mat-select formControlName="month">
            <mat-option *ngFor="let option of monthList" [value]="option.key">{{ option.value }}</mat-option>
          </mat-select>
          <mat-error>{{getErrorMessage("month")}}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 mb-3">
        <mat-label>Year <span class="required-lable-txt">(Required)</span></mat-label>
        <mat-form-field class="example-full-width">
          <mat-select formControlName="year">
            <mat-option *ngFor="let option of yearList" [value]="option.key">{{ option.value }}</mat-option>
          </mat-select>
          <mat-error>{{getErrorMessage("year")}}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 mb-3">
        <mat-label>CVC <span class="required-lable-txt">(Required)</span></mat-label>
        <mat-form-field class="example-full-width">
          <input matInput placeholder="" formControlName="cvc">
          <mat-error>{{getErrorMessage("cvc")}}</mat-error>
        </mat-form-field>
      </div>

      <hr />

      <div class="col-lg-6 col-md-6 col-sm-6 mb-3">
        <mat-label>First Name <span class="required-lable-txt">(Required)</span></mat-label>
        <mat-form-field class="example-full-width">
          <input matInput placeholder="" formControlName="firstName">
          <mat-error>{{getErrorMessage("firstName")}}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 mb-3">
        <mat-label>Last Name <span class="required-lable-txt">(Required)</span></mat-label>
        <mat-form-field class="example-full-width">
          <input matInput placeholder="" formControlName="lastName">
          <mat-error>{{getErrorMessage("lastName")}}</mat-error>
        </mat-form-field>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-6 mb-3">
        <mat-label>Address <span class="required-lable-txt">(Required)</span></mat-label>
        <mat-form-field class="example-full-width">
          <input matInput placeholder="" formControlName="address">
          <mat-error>{{getErrorMessage("address")}}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 mb-3">
        <mat-label>City <span class="required-lable-txt">(Required)</span></mat-label>
        <mat-form-field class="example-full-width">
          <input matInput placeholder="" formControlName="city">
          <mat-error>{{getErrorMessage("city")}}</mat-error>
        </mat-form-field>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-6 mb-3">
        <mat-label>State <span class="required-lable-txt">(Required)</span></mat-label>
        <mat-form-field class="example-full-width">
          <mat-select formControlName="state">
            <mat-option *ngFor="let option of stateList" [value]="option.key">{{ option.value }}</mat-option>
          </mat-select>
          <mat-error>{{getErrorMessage("state")}}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 mb-3">
        <mat-label>Zipcode <span class="required-lable-txt">(Required)</span></mat-label>
        <mat-form-field class="example-full-width">
          <input matInput placeholder="" formControlName="zipcode">
          <mat-error>{{getErrorMessage("zipcode")}}</mat-error>
        </mat-form-field>
      </div>

      <hr />


    </div>

    <div class="row mt-3">
      <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p class="text-right">Fee: 3%*</p>
      </div>
      <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p>Total</p>
      </div>
      <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p class="text-right total-amt">${{total}}</p>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <button mat-stroked-button color="primary" type="button" class="blue-btn me-4" (click)="backToPayment()">Back</button>
        <button mat-stroked-button color="primary" type="submit" class="float-end" >Submit Payment</button>
      </div>
    </div>

  </form>

</div>

