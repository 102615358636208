
<h1 class="form-title">
  Contact
</h1>

<div class="form-content">

  <div class="row">
    <div class="col-lg-6">
      <p class="paragraph-tag orange"><b>Hours of Operation</b></p>
      <p class="paragraph-tag"><b>Monday</b> - 8am to 5pm</p>
      <p class="paragraph-tag"><b>Tuesday</b> - 8am to 5pm</p>
      <p class="paragraph-tag"><b>Wednesday</b> - 8am to 5pm</p>
      <p class="paragraph-tag"><b>Thursday</b> - 8am to 5pm</p>
      <p class="paragraph-tag"><b>Friday</b> - 8am to 5pm</p>
      <p class="paragraph-tag"><b>Saturday Sunday</b> - CLOSED</p>

      <p class="paragraph-tag orange mt-4"><b>Sales Contact</b></p>
      <p class="paragraph-tag"><b>Email</b>: <a href="mailto:sales@otrleasing.com">sales@otrleasing.com</a></p>
      <p class="paragraph-tag"><b>Phone</b>: <a href="tel:(888) 338-9948">(888) 338-9948</a></p>

      <p class="paragraph-tag orange mt-4"><b>Customer Service Contact</b></p>
      <p class="paragraph-tag"><b>Email</b>: <a href="mailto:customerservice@otrleasing.com">customerservice@otrleasing.com</a></p>
      <p class="paragraph-tag"><b>Phone</b>: <a href="tel:(877) 440-6543">(877) 440-6543</a></p>

    </div>

    <div class="col-lg-6">
      <p class="paragraph-tag orange"><b>Send Us A Message: </b></p>
      <form class="example-form" [formGroup]="data" (ngSubmit)="onSubmit()">
        <div class="row mt-2">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <mat-label>Choose A Lease</mat-label>
            <mat-form-field class="example-full-width">
              <mat-select formControlName="lease">
                <mat-option *ngFor="let option of LeaseList" [value]="option.key">{{ option.value }}</mat-option>
              </mat-select>
              <mat-error>{{getErrorMessage("lease")}}</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <mat-label>Issue Type</mat-label>
            <mat-form-field class="example-full-width">
              <mat-select formControlName="issueType">
                <mat-option *ngFor="let option of IssueTypeList" [value]="option.key">{{ option.value }}</mat-option>
              </mat-select>
              <mat-error>{{getErrorMessage("issueType")}}</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <mat-label>Message</mat-label>
            <mat-form-field class="example-full-width">
              <textarea matInput placeholder="" formControlName="message" rows="5"></textarea>
              <mat-error>{{getErrorMessage("message")}}</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <button mat-stroked-button color="primary" type="submit" class="float-end">Send</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="row d-none">
    <div class="col-lg-12">
      <!-- Chat bot UI start -->
      <div class="chat-screen" [ngClass]="{'show-chat': toggle}">
        <div class="chat-header">
          <div class="chat-header-title">
            Let’s chat
          </div>
        </div>
        <div class="chat-body">
          <div class="chat-start">{{chatTime}}</div>
          <div id="chatbody"></div>
          <div class="chat-bubble me" [hidden]="loading">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto;display: block;shape-rendering: auto;width: 43px;height: 20px;" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
              <circle cx="0" cy="44.1678" r="15" fill="#ffffff">
                <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5" repeatCount="indefinite" values="57.5;42.5;57.5;57.5" keyTimes="0;0.3;0.6;1" dur="1s" begin="-0.6s"></animate>
              </circle>
              <circle cx="45" cy="43.0965" r="15" fill="#ffffff">
                <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5" repeatCount="indefinite" values="57.5;42.5;57.5;57.5" keyTimes="0;0.3;0.6;1" dur="1s" begin="-0.39999999999999997s"></animate>
              </circle>
              <circle cx="90" cy="52.0442" r="15" fill="#ffffff">
                <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5" repeatCount="indefinite" values="57.5;42.5;57.5;57.5" keyTimes="0;0.3;0.6;1" dur="1s" begin="-0.19999999999999998s"></animate>
              </circle>
            </svg>
          </div>
        </div>
        <div class="chat-input">
          <input type="text" placeholder="Type a message..." [(ngModel)]="input" id="input" (keydown.enter)="PostComment()">
          <div class="input-action-icon">
            <a (click)="PostComment()">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-send">
                <line x1="22" y1="2" x2="11" y2="13"></line>
                <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div class="chat-bot-icon" (click)="toggleChat()">
        <!--<img src="img/we-are-here.svg" />-->
        <svg xmlns="http://www.w3.org/2000/svg" [ngClass]="{'animate': !toggle}" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-square animate"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg>
        <svg xmlns="http://www.w3.org/2000/svg" [ngClass]="{'animate': toggle}" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x "><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
      </div>
      <!-- Chat Bot UI Ends -->
    </div>
  </div>

</div>

