<h1 class="form-title">
  Fleet Pride
</h1>

<div class="form-content">
  <div class="row justify-content-md-center">
    <div class="col-md-12">

      <div class="row mb-5">
        <div class="col-md-12 text-center">
          <img src="assets/images/fleet_pride_logo.png" class="drs-title-img">
        </div>
        <div class="col-md-12">
          <p class="drs-first-section-p">
            OTR Leasing is proud to partner with FleetPride, offering our customers access to nationwide service centers, preventative
            maintenance specials, and exclusive parts and labor discounts. This collaboration ensures your operations remain reliable and
            efficient, keeping you on the road to success.
          </p>
        </div>


        <div class="col-md-12 features-benefits">
          <h2 class="f-b-heading text-center">Features & Benefits</h2>
          <div class="row justify-content-md-center">
            <div class="col-md-12" style="max-width: 925px">
              <div class="row">
                <div class="col-lg-6 f-b-col">
                  <img src="assets/images/fleet_pride_1.png" class="p-float-left-img"> <span class="f-b-text">Access to Nationwide Service Centers</span>
                </div>
                <div class="col-lg-6 f-b-col">
                  <img src="assets/images/fleet_pride_3.png" class="p-float-left-img"> <span class="f-b-text">Flat Rate for Labor Costs</span>
                </div>
                <div class="col-lg-6 f-b-col">
                  <img src="assets/images/fleet_pride_2.png" class="p-float-left-img height-35"> <span class="f-b-text">Preventative maintenance specials</span>
                </div>
                <div class="col-lg-6 f-b-col">
                  <img src="assets/images/fleet_pride_4.png" class="p-float-left-img height-35"> <span class="f-b-text">Discounts on Parts</span>
                </div>
              </div>

            </div>
          </div>

        </div>

        <div class="col-md-12 mt-4">
          <div>
            <mat-icon class="p-float-left-icons" fontIcon="check"></mat-icon>
            <p class="drs-p">
              <span class="fp-subtitle">Oil Change: $429.99*</span> <br />
              *Offer applies to Oil Change when CITGARD engine oil and Baldwin filters are used. Includes up to 10 gallons of CITGARD 600 15W40 engine oil, (1) oil
              filter, (1) primary fuel filter and (1) secondary fuel filter. Additional charges will apply for additional filters needed or oil in addition to any substitute filters
              and oil requested by customer.
            </p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="scan-bar-container">
            <mat-icon class="p-float-left-icons" fontIcon="check"></mat-icon>
            <p class="drs-p">
              <span class="fp-subtitle">PM A: $149.99</span> <br />
              Includes lube of chassis and inspection only. PM Inspection sheet to be used.
            </p>
          </div>
        </div>
        <div class="col-md-6">         
            <img src="assets/images/fleet_pride_scancode.png" class="fleet-scanbar">         
        </div>
        <div class="col-md-12">
          <div>
            <mat-icon class="p-float-left-icons" fontIcon="check"></mat-icon>
            <p class="drs-p">
              <span class="fp-subtitle">PM B: $574.99*</span> <br />
              Includes Oil Change, PM A, and check all fluid levels, including but not limited to washer fluid, power steering, transmission, and front/rear differentials. If
              fluid is needed, customer will be charged per quart of fluid used with no additional labor.
              *Offer applies to Oil Change when CITGARD engine oil and Baldwin filters are used. Includes up to 10 gallons of CITGARD 600 15W40 engine oil, (1) oil
              filter, (1) primary fuel filter, and (1) secondary fuel filter. Additional charges will apply for additional filters or oil in addition to any substitute filters and oil
              requested by the customer.
            </p>
          </div>
        </div>

        <div class="col-md-12 mt-2 text-center">
          <p class="fp-subtitle mb-0">Accessing the Benefits of Our Partnership is Simple</p>
          <p class="mb-0">
            Find the nearest FleetPride service location, mention you're with us, and provide your OTR Leasing Account Number and any
            necessary contact or truck information.
          </p>
          <p style="font-weight: bold;">Account #: 503612</p>
        </div>

      </div>

    </div>
  </div>
</div>
