<h1 class="form-title">
  Insurance Requirements
</h1>

<div class="form-content">

  <div class="row justify-content-md-center">
    <div class="col-md-12 mt-3">

      <table class="table table-bordered">
        <thead>
          <tr>
            <th colspan="2">Lease Required</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Auto Liability (carrier or own authority) and Non-Trucking Liability</td>
            <td>Auto Liability (carrier or own authority) and Non-Trucking Liability</td>
          </tr>
          <tr>
            <td>Physical Damage/ Full Coverage w/ Comp and Collision</td>
            <td>
              ACV or Stated Value: <br>
              Minimum $80,000 if Model Year is 3 Years old or Newer  <br>
              Minimum $70,000 if Model Year is 4 Years old <br>
              Minimum $60,000 if Model Year is 5 Years and older <br>
              $2,500 maximum deductible
            </td>
          </tr>
          <tr>
            <td>Additional Insured</td>
            <td>
              OTR Leasing, LLC <br>
              PO Box 25934 <br>
              Overland Park, KS 66225
            </td>
          </tr>
          <tr>
            <td>Loss Payee</td>
            <td>
              OTR Leasing, LLC <br>
              PO Box 25934 <br>
              Overland Park, KS 66225
            </td>
          </tr>
          <tr>
            <td>Insured Name</td>
            <td>Lessee Must be Listed as Insured</td>
          </tr>
          <tr>
            <td>Further Insurance Requirements</td>
            <td>
              Above coverage is required at all times throughout the lease period. <br>
              All insurance policies must have correct addresses at all times. <br>
              Any changes must be provided to Insurance Administrator
            </td>
          </tr>
        </tbody>
      </table>

    </div>

    <div class="col-md-12 mt-4">

      <table class="table table-bordered">
        <thead>
          <tr>
            <th colspan="2">Insurance Administrator</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Berkshire Risk</td>
            <td>
              PO Box 25934 <br>
              Overland Park, KS 66225
            </td>
          </tr>
          <tr>
            <td>*all insurance compliance issues should be directed to</td>
            <td>
              Phone - <a class="d-l-link" href="tel:(888)277-5270">(888)277-5270</a> <br />
              Fax - (913) 433-7150 <br />
              E-Mail - <a class="d-l-link" href="mailto:otrleasing@berkshirerisk.com">otrleasing@berkshirerisk.com</a>
            </td>
          </tr>
        </tbody>
      </table>

    </div>

  </div>

</div>
