import { Action, NgxsOnInit, Select, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { HttpServiceService } from '../../services/http-service.service';
import { DashboardStateModel } from './dashboard.model';
import { DashboardAction } from './dashboard.actions';
import { environment } from '../../../environments/environment';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { OtrTokenActions } from '../otr-token/otr-token.actions';
import { CustomerState } from '../customer/customer.state';

@State<DashboardStateModel>({
  name: 'dashboard',
  defaults: <DashboardStateModel>{
  }
})

@Injectable()

export class DashboardState implements NgxsOnInit {
  baseUrl: string;
  @Select(CustomerState.GetCustomerId) customer$: Observable<string>
  constructor(
    private http: HttpServiceService,
    private store: Store) {

    this.baseUrl = environment.lease_path.base_uri;
  }

  ngxsOnInit(ctx: StateContext<any>): void {
     this.store.dispatch(new DashboardAction.getData());
  }


  @Selector()
  static GetData(state: DashboardStateModel): any {
    return state;
  }



  // @Action(DashboardAction.getData)
  // onGetData(ctx: StateContext<DashboardStateModel>) {

  //   this.customer$.subscribe(id => {

  //     if (id == undefined)
  //       return;

  //     //Dashboard Api
  //     const url = `${this.baseUrl}Customers/${id}/Dashboard`;

  //     return this.http.getDataWithToken(url).pipe(
  //       tap((responseArray: DashboardStateModel[]) => {

  //         const firstItem = responseArray[0];

  //         if (firstItem == undefined)
  //           return;

  //         ctx.patchState({
  //           id: firstItem.id,
  //           firstName: firstItem.firstName,
  //           businessName: firstItem.businessName,
  //           leaseId: firstItem.leaseId,
  //           nextDueDate: firstItem.nextDueDate,
  //           baseLeasePaymentDue: firstItem.baseLeasePaymentDue,
  //           escrowAmt: firstItem.escrowAmt || '0',
  //           warrantyAmt: firstItem.warrantyAmt,
  //           weeklyPayment: firstItem.weeklyPayment
  //         });
  //       }),
  //       catchError((error) => {
  //         console.error('Error while fetching dashboard api:', error);
  //         return throwError(error);
  //       })
  //     ).subscribe();

  //   });

  // }
}
