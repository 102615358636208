import { ContactStateModel } from "./contact.model";


export class AddContact {
  static readonly type = '[Contact] Add Contact';
  constructor(public payload: ContactStateModel) { }
}

export namespace ContactAction {
  export class GetData {
    static readonly type = '[Contact] Get Data';
    //constructor(public leaseId: string) { }
  }
}
